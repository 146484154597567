import React, { useState, useHook, useEffect, useRef } from 'react';
import Header from '../Header';
import SideBar from '../SideBar';
import Footer from '../Footer';
import Loader from '../Loader';
import { get, post, put, deleteM } from "../../utility/Adapter";
import { withParams } from "../HOC";
import axios from 'axios';
import Parser from 'html-react-parser';

const CandidateProfile = (props) => {
    const [loading, setLoading] = useState(false);
    const [percentage, setPercentage] = useState('0%');
    const [saveBtn, setSaveBtn] = useState(false);
    const [departments, setDepartments] = useState([]);
    const [myProfile, setMyProfile] = useState({
        candidate_education_details: {
            course_start_f: null,
            course_end_f: null,
            school_name: null,
            board_name: null,
            state: null
        },
        candidate_nurse_course: {

        },
        candidate_language_course: {

        }
    });
    const [countries, setCountries] = useState([]);
    const [nationality, setNationality] = useState([]);
    const [speaking, setSpeaking] = useState([]);
    const [hobbies, setHobbies] = useState([]);
    const [designations, setDesignations] = useState([]);
    const [languageSkills, setLanguageSkills] = useState([]);
    const [candidateStatus, setCandidateStatus] = useState(0);
    const [step, setStep] = useState("0");
    const [candidateId, setCandidateId] = useState(null);
    const [image, setImage] = useState(null);
    const [candidateResume, setCandidateResume] = useState(null);
    const [schoolsDiv, setSchoolsDiv] = useState(null);
    const [schoolsArr, setSchoolsArr] = useState([]);
    const [candidateSchools, setCandidateSchools] = useState([]);
    const [candidateCollege, setCandidateCollege] = useState([]);

    const [courseDiv, setCourseDiv] = useState(null);
    const [courseArr, setCourseArr] = useState([]);
    const [affliators, setAffliators] = useState([]);



    useEffect(() => {
        _loadDepartments();
    }, []);

    useEffect(() => {
        _loadProfile();
        _loadCountry();
        _loadNationality();
        _loadSpeaking();
        _loadHobbies();
        _loadDesignations();
        _loadAffliates();


        /*setTimeout(function () {
            window.$(function () {
                
                window.$(".date-picker").datepicker({
                    dateFormat: 'MM yy',
                    changeMonth: true,
                    changeYear: true,
                    showButtonPanel: true,
                    maxDate: '+28D',
                    yearRange: '1970:2023',

                    onClose: function (dateText, inst) {
                        function isDonePressed() {
                            return (window.$('#ui-datepicker-div').html().indexOf('ui-datepicker-close ui-state-default ui-priority-primary ui-corner-all ui-state-hover') > -1);
                        }
                        if (isDonePressed()){
                            var month = window.$("#ui-datepicker-div .ui-datepicker-month :selected").val();
                            var year = window.$("#ui-datepicker-div .ui-datepicker-year :selected").val();
                            window.$(this).val(window.$.datepicker.formatDate('MM yy', new Date(year, month, 1)));
                        }
                    }
                });

                window.$(".date-picker").focus(function () {
                    window.$(".ui-datepicker-calendar").hide();
                    window.$("#ui-datepicker-div").position({
                        my: "center top",
                        at: "center bottom",
                        of: window.$(this)
                    });
                });
            });
            
        }, 10000);*/
      }, [departments]);

    const _loadAffliates = () => {
        setLoading(true);

        get('affiliators').then(async function (response) {
            setAffliators(response.data.data);
        });

    }

    const _loadDepartments = () => {
        get('departments/en').then(async function (response) {
            setDepartments(response.data.data);
        }).catch(function (error) {
            console.log(error);
            setDepartments([])
        });
    }

    const _loadProfile = () => {
        setLoading(true);
        get('admin/candidate/profile/' + props.params.id).then(async function (response) {
            setMyProfile(response.data.data);
            setCandidateStatus(response.data.data.candidate_status);
            setStep(response.data.data.candidate_step);
            setCandidateId(response.data.data.candidate_id);
            setCandidateResume(response.data.data.candidate_resume);
            setCandidateSchools(response.data.data.candidate_schools);

            if (response.data.data.candidate_colleges != undefined)
                setCandidateCollege(response.data.data.candidate_colleges);

            if (response.data.data.candidate_additional_note != undefined) {
                document.getElementById("additional_note").value = response.data.data.candidate_additional_note;
            }

            if (response.data.data.candidate_image != null)
                setImage(process.env.REACT_APP_BASE_URL + "candidates/images/" + response.data.data.candidate_image);

            setTimeout(function () {
                document.getElementById("country").value = response.data.data.candidate_residency;
                document.getElementById("nationality").value = response.data.data.candidate_nationality;

                //if (response.data.data.candidate_degree != 'STUDENT') {
                    document.getElementById("nc_total_experience").value = response.data.data.candidate_experience;
                //}

                if (response.data.data.candidate_affiliator_status != undefined) {
                    document.getElementById("affiliate_status").value = response.data.data.candidate_affiliator_status;
                    document.getElementById("affiliator_note").value = response.data.data.candidate_affiliator_note;

                    if (response.data.data.affiliator_id != undefined) {
                        document.getElementById('affiliator').value = response.data.data.affiliator_id;
                    }

                }

                if (response.data.data.candidate_gender != undefined)
                    document.getElementById("gender").value = response.data.data.candidate_gender;

                if (response.data.data.candidate_family_status != undefined)
                    document.getElementById("family_status").value = response.data.data.candidate_family_status;

                if (response.data.data.candidate_education_details.country != undefined) {
                    document.getElementById("hs_country").value = response.data.data.candidate_education_details.country_f;
                }

                if (response.data.data.candidate_nurse_course != undefined) {
                    if (response.data.data.candidate_nurse_course.course != undefined) {
                        document.getElementById("nc_course").value = response.data.data.candidate_nurse_course.country_f;
                    }

                    if (response.data.data.candidate_nurse_course.country != undefined) {
                        document.getElementById("nc_country").value = response.data.data.candidate_nurse_course.country_f;

                        if (response.data.data.candidate_nurse_course.country) {
                            setPercentage('20%');
                        }

                    }
                }

                if (response.data.data.candidate_professional != undefined) {
                    //if (response.data.data.candidate_degree != 'STUDENT') {
                        for (var j = 0; j < response.data.data.candidate_professional.length; j++) {
                            document.getElementById("experience_start_" + j).value = response.data.data.candidate_professional[j].experience_start_f;
                            document.getElementById("experience_end_" + j).value = response.data.data.candidate_professional[j].experience_end_f;
                            document.getElementById("experience_designation_" + j).value = response.data.data.candidate_professional[j].designation_id;
                            document.getElementById("experience_employer_" + j).value = response.data.data.candidate_professional[j].employer_name;
                            //document.getElementById("experience_bed_capacity_" + j).value = response.data.data.candidate_professional[j].bed_capacity;
                            document.getElementById("experience_bed_capacity_" + j).value = getDepartmentNames(response.data.data.candidate_professional[j].bed_capacity, departments);
                        }
                    //}

                    if (response.data.data.candidate_professional.length > 0) {
                        setPercentage('60%');
                    }
                }

                if (response.data.data.candidate_language_level != undefined) {
                    var languageLevel = [];
                    for (var j = 0; j < response.data.data.candidate_language_level.length; j++) {
                        var tmp = {
                            name: response.data.data.candidate_language_level[j].speaking_name,
                            language: response.data.data.candidate_language_level[j].language,
                            level: response.data.data.candidate_language_level[j].candidate_language_level
                        }

                        languageLevel.push(tmp);

                    }

                    setLanguageSkills(languageLevel);

                    if (response.data.data.candidate_language_level.length > 0) {
                        setPercentage('80%');
                    }
                }

                if (response.data.data.candidate_hobbies != undefined) {
                    for (var j = 0; j < response.data.data.candidate_hobbies.length; j++) {
                        if (document.getElementById("hb_" + response.data.data.candidate_hobbies[j].hobby_id) != undefined)
                            document.getElementById("hb_" + response.data.data.candidate_hobbies[j].hobby_id).checked = true;
                    }

                    if (response.data.data.candidate_hobbies.length > 0) {
                        setPercentage('100%');
                    }
                }

                if (response.data.data.candidate_degree == 'STUDENT') {
                    var schools = '';

                    if (document.getElementById("schools") != null) {
                        schools = document.getElementById("schools").innerHTML;
                    }

                    setSchoolsDiv(schools);
                }

                if (response.data.data.candidate_language_course.lc_language_level != undefined) {
                    document.getElementById("lc_language_level").value = response.data.data.candidate_language_course.lc_language_level;
                }

                if (response.data.data.candidate_language_course.country != undefined) {
                    document.getElementById("lc_country").value = response.data.data.candidate_language_course.country;
                }

                if (response.data.data.candidate_birth_place != undefined)
                    document.getElementById("birth_place").value = response.data.data.candidate_birth_place;

                setLoading(false);

                setTimeout(function () {
                    window.$(function () {

                        const d = new Date();
                        let year = d.getFullYear();

                        window.$(".date-picker").datepicker({
                            dateFormat: 'MM yy',
                            changeMonth: true,
                            changeYear: true,
                            showButtonPanel: true,
                            maxDate: '+28D',
                            yearRange: '1970:' + year,

                            onClose: function (dateText, inst) {
                                function isDonePressed() {
                                    return (window.$('#ui-datepicker-div').html().indexOf('ui-datepicker-close ui-state-default ui-priority-primary ui-corner-all ui-state-hover') > -1);
                                }
                                if (isDonePressed()) {
                                    var month = window.$("#ui-datepicker-div .ui-datepicker-month :selected").val();
                                    var year = window.$("#ui-datepicker-div .ui-datepicker-year :selected").val();
                                    window.$(this).val(window.$.datepicker.formatDate('MM yy', new Date(year, month, 1)));
                                }
                            }
                        });

                        window.$(".date-picker").focus(function () {
                            window.$(".ui-datepicker-calendar").hide();
                            window.$("#ui-datepicker-div").position({
                                my: "center top",
                                at: "center bottom",
                                of: window.$(this)
                            });
                        });
                    });
                }, 500);

            }, 100)

        }).catch(function (error) {
            console.log(error);
        });
    }

    const _loadCountry = () => {
        get('countries/en').then(async function (response) {
            setCountries(response.data.data);
            setTimeout(function () {
                var nursingCourse = document.getElementById('nursingCourse').innerHTML;
                if (nursingCourse != null) {
                    setCourseDiv(nursingCourse);
                    document.getElementById('nursingCourse').innerHTML = '<div></div>';
                }

            }, 1000)

        }).catch(function (error) {
            console.log(error);
        });
    }

    const _loadNationality = () => {
        get('nationality/en').then(async function (response) {
            setNationality(response.data.data);
        }).catch(function (error) {
            console.log(error);
        });
    }

    const _loadSpeaking = () => {
        get('speaking/en').then(async function (response) {
            setSpeaking(response.data.data);
        }).catch(function (error) {
            console.log(error);
        });
    }

    const _loadHobbies = () => {
        get('hobbies/en').then(async function (response) {
            setHobbies(response.data.data);
        }).catch(function (error) {
            console.log(error);
        });
    }

    const _loadDesignations = () => {
        get('destinations/en').then(async function (response) {
            setDesignations(response.data.data);
        }).catch(function (error) {
            console.log(error);
        });
    }

    const _addLanguageSkills = () => {
        var language = document.getElementById('language');
        var language_level = "";
        //document.getElementById('language_level').value;

        if (language.value) {

            var exists = false;
            for (var i = 0; i < languageSkills.length; i++) {
                if (languageSkills[i].language == language.value && languageSkills[i].level == language_level) {
                    exists = true;
                }
            }

            var tmp = {
                name: language.options[language.selectedIndex].text,
                language: language.value,
                level: language_level
            }

            if (exists == false)
                setLanguageSkills([...languageSkills, tmp]);
        }
    }

    const enableEdit = (e) => {
        setSaveBtn(true)
    }

    Element.prototype.insertAfter = function (newEl) {
        this.parentNode.insertBefore(newEl, this.nextSibling);
    }

    function multipleInArray(arr, values) {
        return values.every(value => {
            return arr.includes(value);
        });
    }

    const save = (e) => {
        removeErrors();
        var error = false;

        var elements = document.getElementsByClassName('required');
        for (var i = 0; i < elements.length; i++) {
            var error_message = elements[i].getAttribute('data-error');

            if (elements[i].value == "") {
                createError(elements[i], error_message);
                error = true;
            }
        }

        var courseData = [];
        var school_details = "";
        var course_details = "";
        var experienceData = [];
        var collegeData = [];

        if (myProfile.candidate_degree == 'STUDENT') {
            var school_start = document.getElementsByName('school_start[]');
            var school_end = document.getElementsByName('school_end[]');
            var class_from = document.getElementsByName('class_from[]');
            var class_to = document.getElementsByName('class_to[]');
            var school = document.getElementsByName('school[]');
            var school_country = document.getElementsByName('school_country[]');

            var schoolData = [];
            var collegeData = [];

            for (var j = 0; j < school_start.length; j++) {
                if (school_start[j].value) {
                    schoolData.push({
                        school_start: school_start[j].value,
                        school_end: school_end[j].value,

                        class_from: class_from[j].value,
                        class_to: class_to[j].value,

                        school: school[j].value,
                        country: school_country[j].value
                    })
                }

            }

            var college_start = document.getElementsByName('college_start[]');
            var college_end = document.getElementsByName('college_end[]');
            var college_school = document.getElementsByName('college_school[]');
            var college_course = document.getElementsByName('college_course[]');
            var college_state = document.getElementsByName('college_state[]');
            var college_country = document.getElementsByName('college_country[]');

            for (var j = 0; j < college_start.length; j++) {
                if (college_start[j].value) {
                    collegeData.push({
                        college_start: college_start[j].value,
                        college_end: college_end[j].value,

                        college_school: college_school[j].value,
                        college_course: college_course[j].value,

                        college_state: college_state[j].value,
                        country: college_country[j].value
                    })
                }

            }
        }
        else {
            var nc_start = document.getElementsByName('nc_start[]');
            var nc_end = document.getElementsByName('nc_end[]');
            var nc_course = document.getElementsByName('nc_course[]');
            var nc_college = document.getElementsByName('nc_college[]');
            var nc_university = document.getElementsByName('nc_university[]');
            var nc_state = document.getElementsByName('nc_state[]');
            var nc_country = document.getElementsByName('nc_country[]');
            var tmp = [];

            for (var j = 0; j < nc_start.length; j++) {
                for (var k = 0; k < tmp.length; k++) {
                    if (tmp[k] == nc_course[j].value) {
                        alert("Nursing courses are often selected repeatedly");
                        error = true;
                        return false;
                    }
                }

                tmp.push(nc_course[j].value);

                if (nc_start[j].value) {
                    courseData.push({
                        nc_start: nc_start[j].value,
                        nc_end: nc_end[j].value,

                        nc_course: nc_course[j].value,
                        nc_college: nc_college[j].value,

                        nc_university: nc_university[j].value,
                        nc_state: nc_state[j].value,

                        nc_country: nc_country[j].value
                    })
                }
            }
        }

        if (error == false) {

            var first_name = document.getElementById('first_name').value;
            var last_name = document.getElementById('last_name').value;
            var phone = document.getElementById('phone').value;
            var email = document.getElementById('email').value;
            var gender = document.getElementById('gender').value;
            var family_status = document.getElementById('family_status').value;
            var country = document.getElementById('country').value;
            var nationality = document.getElementById('nationality').value;
            //var passport_number = document.getElementById('passport_number').value;
            //var passport_expiry_date = document.getElementById('passport_expiry_date').value;
            var present_address = document.getElementById('present_address').value;
            var present_address_passport = document.getElementById('present_address_passport').value;
            var dob = document.getElementById('dob').value;

            /*EDUCATIONAL DETAILS HIGHER SECONDARY*/
            var hs_start = document.getElementById('hs_start').value;
            var hs_end = document.getElementById('hs_end').value;
            var hs_school = document.getElementById('hs_school').value;
            var hs_university = document.getElementById('hs_universiry').value;
            var hs_state = document.getElementById('hs_state').value;
            var hs_country = document.getElementById('hs_country').value;

            /*EDUCATIONAL DETAILS NURSING*/
            if (myProfile.candidate_degree != 'STUDENT') {
                var nc_start = document.getElementById('nc_start').value;
                var nc_end = document.getElementById('nc_end').value;
                var nc_course = document.getElementById('nc_course').value;
                var nc_college = document.getElementById('nc_college').value;
                var nc_university = document.getElementById('nc_universiry').value;
                var nc_state = document.getElementById('nc_state').value;
                var nc_country = document.getElementById('nc_country').value;
            }
            else {
                var nc_total_experience, nc_start, nc_end, nc_course, nc_college, nc_university, nc_state, nc_country, experience_bed_capacity, experience_employer, experience_designation, experience_start, experience_end = '';
            }

            for (var i = 0; i < 5; i++) {

                var ed = {
                    experience_start: document.getElementById('experience_start_' + i).value,
                    experience_end: document.getElementById('experience_end_' + i).value,
                    experience_designation: document.getElementById('experience_designation_' + i).value,
                    experience_employer: document.getElementById('experience_employer_' + i).value,
                    experience_bed_capacity: getDepartmentIds(document.getElementById('experience_bed_capacity_' + i).value, departments)
                }

                if (document.getElementById('experience_start_' + i).value != "") {
                    experienceData.push(ed);
                }

            }

            var experience_start = document.getElementById('experience_start_0').value;
            var experience_end = document.getElementById('experience_end_0').value;
            var experience_designation = document.getElementById('experience_designation_0').value;
            var experience_employer = document.getElementById('experience_employer_0').value;
            var experience_bed_capacity = getDepartmentIds(document.getElementById('experience_bed_capacity_0').value, departments);
            var nc_total_experience = document.getElementById('nc_total_experience').value;


            var languageSkillsCandidate = JSON.stringify(languageSkills);
            var hobbiesElements = document.getElementsByClassName('hobbies');
            var hobbies = [];

            for (var j = 0; j < hobbiesElements.length; j++) {
                if (hobbiesElements[j].checked) {
                    hobbies.push(hobbiesElements[j].value);
                }
            }

            var candidateHobbies = JSON.stringify(hobbies);
            var lc_language_level = document.getElementById('lc_language_level').value;
            var lc_start = document.getElementById('lc_start').value;
            var lc_end = document.getElementById('lc_end').value;
            var lc_school = document.getElementById('lc_school').value;
            var lc_state = document.getElementById('lc_state').value;
            var lc_country = document.getElementById('lc_country').value;

            var additionalNote = document.getElementById('additional_note').value;
            var mother_tongue = document.getElementById('mother_tongue').value;
            var birth_place = document.getElementById('birth_place').value;

            var raw = JSON.stringify({
                "candidate_id": props.params.id,
                "candidate_residency": country,
                "candidate_nationality": nationality,
                "candidate_gender": gender,
                "candidate_family_status": family_status,
                "candidate_passport_details": "",
                "candidate_passport_expiry_date": "",
                "candidate_address": present_address,
                "candidate_passport_address": present_address_passport,
                "candidate_last_name": last_name,
                "candidate_first_name": first_name,
                "candidate_dob": dob,
                "candidate_hs_start": hs_start,
                "candidate_hs_end": hs_end,
                "candidate_hs_school": hs_school,
                "candidate_hs_university": hs_university,
                "candidate_hs_state": hs_state,
                "candidate_hs_country": hs_country,
                "candidate_nc_start": nc_start,
                "candidate_nc_end": nc_end,
                "candidate_nc_course": nc_course,
                "candidate_nc_college": nc_college,
                "candidate_nc_university": nc_university,
                "candidate_nc_state": nc_state,
                "candidate_nc_country": nc_country,
                "candidate_languageSkills": languageSkills,
                "candidate_hobbies": candidateHobbies,
                "candidate_experience_total": nc_total_experience,
                "candidate_experience_start": experience_start,
                "candidate_experience_end": experience_end,
                "candidate_experience_designation": experience_designation,
                "candidate_experience_employer": experience_employer,
                "candidate_experience_bed_capacity": experience_bed_capacity,
                "candidate_school_details": JSON.stringify(schoolData),
                "candidate_nc_course_details": JSON.stringify(courseData),
                "candidate_college_details": JSON.stringify(collegeData),
                "candidate_experince": JSON.stringify(experienceData),
                "candidate_additional_note": additionalNote,
                "lc_language_level": lc_language_level,
                "lc_start": lc_start,
                "lc_end": lc_end,
                "lc_school": lc_school,
                "lc_state": lc_state,
                "lc_country": lc_country,
                "mother_tongue": mother_tongue,
                "candidate_email": email,
                "candidate_phone": phone,
                "candidate_birth_place": birth_place
            });

            setLoading(true);
            put('candidates/admin', raw).then(async function (response) {
                setLoading(false);
                setSaveBtn(false);
                _loadProfile();

                if (candidateStatus == 1) {
                    setStep("1");
                    window.location.href = "/documents";
                }
            }).catch(function (error) {
                setLoading(false);
                console.log(error);
            });

        }
    }

    const experiences = () => {
        var experiences = [];

        for (var i = 1; i <= 20; i++) {
            experiences.push(<option value={i}>{i + " Years"}</option>);
        }

        return experiences;
    }

    const experienceDetails = () => {
        var data = [];
        var required = 'required';
        for (var j = 0; j < 5; j++) {
            data.push(
                <div className='row'>
                    <div className="col-sm-2 form-group">
                        <label className="mb-3 top-label">
                            <input type="date" id={"experience_start_" + j} className={"form-control " + required} data-error="Enter start date" />
                            <span style={{ color: 'rgb(94, 92, 92)' }}>Start Date</span>
                        </label>
                    </div>

                    <div className="col-sm-2 form-group">
                        <label className="mb-3 top-label">
                            <input type="date" id={"experience_end_" + j} className={"form-control " + required} data-error="Enter end date" />
                            <span style={{ color: 'rgb(94, 92, 92)' }}>End Date</span>
                        </label>
                    </div>

                    <div className="col-sm-2 form-group">
                        <label className="mb-3 top-label">
                            <select className={"form-control " + required} id={"experience_designation_" + j} data-error="Please select designation">
                                {
                                    designations.map((d, k) =>
                                        <option value={d.destination_id}>
                                            {d.destination_name}
                                        </option>
                                    )
                                }
                            </select>
                            <span style={{ color: 'rgb(94, 92, 92)' }}>Designation</span>
                        </label>
                    </div>

                    <div className="col-sm-4 form-group">
                        <label className="mb-3 top-label">
                            <input type="text" id={"experience_employer_" + j} className={"form-control " + required} data-error="Enter employer name" />
                            <span style={{ color: 'rgb(94, 92, 92)' }}>Employer Name</span>
                        </label>
                    </div>

                    <div className="col-sm-2 form-group">
                        <label className="mb-3 top-label">
                            <input type="text" id={"experience_bed_capacity_" + j} className={"form-control " + required} data-error="Enter department" />
                            <span style={{ color: 'rgb(94, 92, 92)' }}>Department</span>
                        </label>
                    </div>
                </div>
            );

            required = '';
        }

        return data;
    }

    const uploadFile = (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('file', file);
        formData.append('candidateId', props.params.id);
        setImage(URL.createObjectURL(e.target.files[0]));
        setLoading(true);

        /*var reader = new FileReader();

        reader.onload = function (e) {
            setImage(e.target.result);
        };*/

        //const imageSrc = URL.createObjectURL(file[0]);
        //setImage(image);

        /*post('admin/translators/document_upload', formData)
            .then(res => {
                _loadDocuments(1);
                alert("Successfully Uploaded!");
            }).catch(err => console.log(err));*/



        const headers = {
            "Contetnt-Type": "multipart/form-data",
        }

        axios.post(process.env.REACT_APP_BASE_URL + 'candidates/avatar_upload', formData, {
            headers: headers
        })
            .then((res) => {
                setLoading(false);
                alert("Successfully Uploaded!");
                //window.location.href = "/profile";
            })
            .catch((error) => {
                setLoading(false);
                alert('Avatar upload failed, Please try agian')
            })
    }

    const plusSchool = () => {
        //console.log(schoolsDiv)
        //document.getElementById("schools_div").appendChild(schoolsDiv);
        var sDiv = "";
        setSchoolsArr([...schoolsArr, schoolsDiv]);
    }

    const plusCourse = () => {
        var limit = 3;
        if (myProfile.candidate_nurse_course.length > 0) {
            limit = 4 - myProfile.candidate_nurse_course.length;
        }
        if (courseArr.length < limit) {
            setCourseArr([...courseArr, courseDiv]);
        }

    }

    const _saveLanguageSkills = () => {
        var language = document.getElementById("change_language_level").value;

        var raw = JSON.stringify({
            "candidate_language_level": language
        });

        setLoading(true);
        put('candidates/change_language', raw).then(async function (response) {
            setLoading(false);
            setSaveBtn(false);
            _loadProfile();
        }).catch(function (error) {
            console.log(error);
            setLoading(false);
        });

    }

    var languageLevels = {
        NOT_ENTROLLED: "Not yet enrolled",
        A1_ENTROLLED: "A1 Enrolled",
        A1_COMPLETE: "A2 Complete",
        B1_COMPLETE: "B1 Complete",
        B2_COMPLETE: "B2 Complete",
        C1_COMPLETE: "C1 Complete",
        C2_COMPLETE: "C2 Complete",
    };

    var languageLevelsId = [
        { id: "NOT_ENTROLLED", val: "Not yet enrolled" },
        { id: "A1_ENTROLLED", val: "A1 Enrolled" },
        { id: "A1_COMPLETE", val: "A2 Complete" },
        { id: "B1_COMPLETE", val: "B1 Complete" },
        { id: "B2_COMPLETE", val: "B2 Complete" },
        /*{ id: "C1_COMPLETE", val: "C1 Complete" },
        { id: "C2_COMPLETE", val: "C2 Complete" },*/
    ];



    const _remove = (index) => {
        removeProduct(index)
    }

    const removeProduct = (index) => {
        setCourseArr([
            ...courseArr.slice(0, index),
            ...courseArr.slice(index + 1, courseArr.length)
        ]);
    }

    const removeErrors = () => {
        var ele = document.querySelectorAll(".error");

        for (var j = 0; j < ele.length; j++) {
            ele[j].remove();
        }
    }

    const createError = (element, error) => {
        var errorElement = document.createElement("div");
        errorElement.classList.add('error');
        errorElement.innerHTML = error;
        element.insertAfter(errorElement);
        element.focus();
    }

    const _changePassword = () => {
        var password = document.getElementById('password').value;
        var confirm = document.getElementById('confirm_password').value;

        if (password.length == 0) {
            alert("Please enter valid password");
            return false;
        }

        if (password != confirm) {
            alert("Password and confirm password do not match");
            return false;
        }

        var raw = JSON.stringify({
            "candidate_id": props.params.id,
            "password": password
        });

        setLoading(true);
        post('admin/candidates/change_p', raw).then(async function (response) {
            setLoading(false);
            alert("Successfully updated");
        }).catch(function (error) {
            setLoading(false);
        });

    }

    const _addNote = () => {
        var note = document.getElementById('note').value;

        if (note.length == 0) {
            alert("Please enter note");
            return false;
        }

        var raw = JSON.stringify({
            "candidate_id": props.params.id,
            "note": note
        });

        setLoading(true);
        post('admin/candidates/note', raw).then(async function (response) {
            setLoading(false);
            alert("Successfully updated");
            document.getElementById('note').value = "";
        }).catch(function (error) {
            setLoading(false);
        });
    }

    var languageCourseLevels = [
        { id: "Im Moment lerne A1 Niveau", val: "Im Moment lerne A1 Niveau" },
        { id: "Im Moment lerne A2 Niveau", val: "Im Moment lerne A2 Niveau" },
        { id: "Im Moment lerne B1 Niveau", val: "Im Moment lerne B1 Niveau" },
        { id: "mit Abschluss Zertifikat B1", val: "mit Abschluss Zertifikat B1" },
        { id: "Im Moment lerne B2 Niveau", val: "Im Moment lerne B2 Niveau" },
        { id: "mit Abschluss Zertifikat B2", val: "mit Abschluss Zertifikat B2" },
    ];

    const _changeStatus = () => {
        var affiliate_status = document.getElementById('affiliate_status').value;
        var affiliator_note = document.getElementById('affiliator_note').value;
        var affiliator = document.getElementById('affiliator').value;

        var e = document.getElementById("affiliator");
        var option = e.options[e.selectedIndex];
        var datarc = option.getAttribute("data-id");

        var raw = JSON.stringify({
            "candidate_id": props.params.id,
            "affiliate_status": affiliate_status,
            "affiliator_note": affiliator_note,
            "affiliator": affiliator,
            "affiliator_code": datarc
        });

        setLoading(true);
        post('admin/candidates/change_affiliate_status', raw).then(async function (response) {
            setLoading(false);
            alert("Successfully updated");
        }).catch(function (error) {
            setLoading(false);
        });
    }

    return (
        <>
            <Header />
            <Loader loading={loading} />

            <main>
                <div class="container">
                    <div class="page-title-container">
                        <div class="row">
                            <div class="col-12 col-md-7">
                                <span class="align-middle text-muted d-inline-block lh-1 pb-2 pt-2 text-small"></span>
                                <h1 class="mb-0 pb-0 display-4 text-capitalize" id="title">
                                    <a href="/candidates">
                                        <i data-acorn-icon="arrow-left" class="icon" data-acorn-size="25"></i>
                                    </a>
                                </h1>
                            </div>
                        </div>
                    </div>



                    <div style={{ display: 'none' }}>
                        <div className='row' id="nursingCourse">

                            <label className="col-sm-4 mb-3 top-label">
                                <input type="text" name="nc_start[]" id="nc_start_" className="form-control required date-picker" data-error="Nursing starting month & year" />
                                <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'capitalize' }}>Starting month & year</span>
                            </label>

                            <label className="col-sm-4 mb-3 top-label">
                                <input type="text" name="nc_end[]" id="nc_end_" className="form-control required date-picker" data-error="Higher secondary ending month & year" />
                                <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'capitalize' }}>Ending month & year</span>
                            </label>

                            <label className="col-sm-4 mb-3 top-label">
                                <select name="nc_course[]" className="form-control required" id="nc_course_" data-error="Please select nursing course">
                                    <option value="">Select</option>
                                    <option value="1">B.Sc Nursing 4 years course</option>
                                    <option value="2">M.Sc Nursing 2 years course</option>
                                    <option value="3">Diploma in Nursing/GNM 3.5 years course</option>
                                    <option value="4">Post Basic B.Sc 2 years course</option>
                                    <option value="5">Diploma in Nursing/GNM 3 years course</option>
                                </select>
                                <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'capitalize' }}>Nursing Course</span>
                            </label>

                            <label className="col-sm-4 mb-3 top-label">
                                <input type="text" name="nc_college[]" id="nc_college_" className="form-control required" data-error="Enter your college" />
                                <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'capitalize' }}>College name</span>
                            </label>

                            <label className="col-sm-4 mb-3 top-label">
                                <input type="text" name="nc_university[]" id="nc_universiry_" className="form-control required" data-error="Enter your University/ Board name" />
                                <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'capitalize' }}>University/ Board name</span>
                            </label>

                            <label className="col-sm-4 mb-3 top-label">
                                <input type="text" name="nc_state[]" id="nc_state_" className="form-control required" data-error="Enter state" />
                                <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'capitalize' }}>State</span>
                            </label>


                            <label className="col-sm-4 mb-3 top-label">
                                <select name="nc_country[]" id="nc_country_" className="form-control required" data-error="Please select country">
                                    <option value="">Select</option>
                                    {
                                        countries.map((c, k) =>
                                            <option value={c.country_id} key={k}>
                                                {c.country_name}
                                            </option>
                                        )
                                    }
                                </select>
                                <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'capitalize' }}>Country</span>
                            </label>
                            <hr />
                        </div>
                    </div>

                    <div className='row'>

                        <div className='col-xl-12'>

                            <div className='row'>
                                <div class="col-3 col-sm-3 col-lg-3">
                                    <label htmlFor="uploadDoc"><img className="img-fluid rounded-md" src={image} style={{ width: 150, height: 150 }} /></label>
                                    <input className="d-none btn btn-outline-primary btn-icon btn-icon-start w-100 w-md-auto" accept="image/*" type="file" style={{ zIndex: 2, top: 0, left: 0, filter: 'alpha(opacity=0)', msFilter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)" }} name="file_source" size="40" onChange={(e) => uploadFile(e)} id="uploadDoc" />
                                </div>
                                <div className='col-sm-3'>
                                    <h3 class="alert-heading" style={{ textTransform: 'capitalize', fontWeight: 'bold' }}>{myProfile.candidate_first_name} {myProfile.candidate_last_name}</h3>
                                    <span>{myProfile.candidate_email}</span><br /><span>{myProfile.candidate_phone}</span>
                                </div>
                                <div class="col-12 col-sm-6 col-lg-3">
                                    <div class="mb-5">
                                        <div>
                                            <div class="heading mb-0 d-flex justify-content-between lh-1-25 mb-3">
                                                <span>Language Level</span>
                                            </div>
                                            <div class="text-small text-muted mb-1"></div>
                                            <div class="cta-1 text-primary">
                                                {myProfile.candidate_current_language_level == 'NOT_ENTROLLED' ? 'Not yet enrolled' : ''}
                                                {myProfile.candidate_current_language_level == 'A1_ENTROLLED' ? 'A1 Enrolled' : ''}
                                                {myProfile.candidate_current_language_level == 'A1_COMPLETE' ? 'A2 Complete' : ''}

                                                {myProfile.candidate_current_language_level == 'B1_COMPLETE' ? 'B1 Complete' : ''}
                                                {myProfile.candidate_current_language_level == 'B2_COMPLETE' ? 'B2 Complete' : ''}
                                                {myProfile.candidate_current_language_level == 'C1_COMPLETE' ? 'C1 Complete' : ''}

                                                {myProfile.candidate_current_language_level == 'C2_COMPLETE' ? 'C2 Complete' : ''}
                                            </div>
                                        </div>
                                        <div class="mb-5">
                                        <div>
                                            <div class="heading mb-0 d-flex justify-content-between lh-1-25 mb-3 pt-5">
                                                <span>KDA Brochure Status</span>
                                            </div>
                                            <div class="text-small text-muted mb-1"></div>
                                            <div class="cta-1 text-primary">
                                                {myProfile.has_read_information_brochure_2024 == true ? "Read" : "Did not read"}
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>

                                <div class="col-12 col-sm-6 col-lg-3">
                                    <div class="mb-5">
                                        <div>
                                            <div class="heading mb-0 d-flex justify-content-between lh-1-25 mb-3">
                                                <span>Meeting Status</span>
                                            </div>
                                            <div class="text-small text-muted mb-1"></div>
                                            <div class="cta-1 text-primary">
                                                {myProfile.candidate_status == 1 ? "Attend" : "Not Attend"}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='card mb-5 mt-5'>
                                <div className='card-body'>
                                    <div className='row'>

                                        <div className="col-sm-3 col-md-3 col-lg-3 col-xxl-3 mb-1">
                                            <label>Affiliator</label>
                                            <select className='form-control' id="affiliator">
                                                {
                                                    affliators.map((a, k) =>
                                                        <option value={a._id} data-id={a.affiliator_id}>{a.affiliator_name} [ {a.affiliator_id} ] </option>
                                                    )
                                                }
                                            </select>
                                        </div>

                                        <div className="col-sm-3 col-md-3 col-lg-3 col-xxl-3 mb-1">
                                            <label>Change status for affiliates</label>
                                            <select className='form-control' id="affiliate_status">
                                                <option value='under review'>Under Review</option>
                                                <option value='in progress'>In Progress</option>
                                                <option value='interview completed'>Interview Completed</option>
                                                <option value='candidate joined'>Candidate Joined</option>
                                                <option value='get paid'>Get Paid</option>
                                                <option value='rejected'>Rejected</option>
                                            </select>
                                        </div>

                                        <div className="col-sm-4 col-md-4 col-lg-4 col-xxl-4 mb-1">
                                            <label>Notes</label>
                                            <input type='text' className='form-control' name='affiliator_note' id='affiliator_note' />
                                        </div>
                                        <div className='col-2 top-label'>
                                            <button class="btn btn-primary" type="button" onClick={() => _changeStatus()} style={{ marginTop: 22 }}>Update</button>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div className='row'>


                                <div className="col-sm-12 mb-1">
                                    <div className='card mb-5'>
                                        <div className='card-body'>
                                            <div className="row">
                                                <div className="col-sm-4 top-label">
                                                    <input type="password" id="password" className="form-control" data-error="New Password" name="password" />
                                                    <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>New Password</span>
                                                </div>
                                                <div className="col-sm-4 top-label">
                                                    <input type="password" id="confirm_password" className="form-control " data-error="Confirm Password" name="confirm_password" />
                                                    <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Confirm Password</span>
                                                </div>
                                                <div className="col-sm-4 mb-3 top-label">
                                                    <button class="btn btn-primary" type="button" onClick={() => _changePassword()}>Change Password</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>




                            <h2 class="small-title">Profile Details</h2>
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xxl-12 mb-1">
                                <div className='card mb-5 mt-5'>
                                    <div className='card-body'>
                                        <div className="row">
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">

                                                <label className="mb-3 top-label">
                                                    <input type="text" className="form-control required" id="first_name" defaultValue={myProfile.candidate_first_name} data-error="Please enter first name" />
                                                    <span style={{ color: 'rgb(94, 92, 92)' }}>NAME</span>
                                                </label>

                                                <label className="mb-3 top-label">
                                                    <input type="text" className="form-control required" id="last_name" defaultValue={myProfile.candidate_last_name} data-error="Please enter last name" />
                                                    <span style={{ color: 'rgb(94, 92, 92)' }}>LAST NAME</span>
                                                </label>

                                                <label className="mb-3 top-label">
                                                    <input type="text" id="email" className="form-control required" defaultValue={myProfile.candidate_email} />
                                                    <span style={{ color: 'rgb(94, 92, 92)' }}>EMAIL</span>
                                                </label>

                                                <label className="mb-3 top-label">
                                                    <input type="text" className="form-control" id="phone" defaultValue={myProfile.candidate_phone} />
                                                    <span style={{ color: 'rgb(94, 92, 92)' }}>PHONE</span>
                                                </label>


                                                <label className="mb-3 top-label">
                                                    <input defaultValue={myProfile.candidate_dob} type="date" id="dob" className="form-control required" data-error="Please enter date of birth" />
                                                    <span style={{ color: 'rgb(94, 92, 92)' }}>DATE OF BIRTH</span>
                                                </label>

                                                <label className="mb-3 top-label">
                                                    <select className="form-control required" id="gender" data-error="Please select gender">
                                                        <option value="">Select</option>
                                                        <option value="m">Male</option>
                                                        <option value="f">Female</option>
                                                        <option value="t">Transgender</option>
                                                        <option value="o">Others</option>
                                                    </select>
                                                    <span style={{ color: 'rgb(94, 92, 92)' }}>GENDER</span>
                                                </label>

                                                <label className="mb-3 top-label">
                                                    <select className="form-control required" id="family_status" data-error="Please select family status">
                                                        <option value="">Select</option>
                                                        <option value="s">Single</option>
                                                        <option value="m">Married</option>
                                                        <option value="w">Widowed</option>
                                                        <option value="d">Divorced</option>
                                                    </select>
                                                    <span style={{ color: 'rgb(94, 92, 92)' }}>FAMILY STATUS</span>
                                                </label>

                                                <label className="mb-3 top-label">
                                                    <select name="country" id="country" className="form-control required" defaultValue={myProfile.candidate_residency} data-error="Please select country or region of residence">
                                                        <option value="">Select</option>
                                                        {
                                                            countries.map((c, k) =>
                                                                <option value={c.country_id} key={k}>
                                                                    {c.country_name}
                                                                </option>
                                                            )
                                                        }
                                                    </select>
                                                    <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Country or region of residence</span>
                                                </label>

                                                <label className="mb-3 top-label">
                                                    <select name="nationality" id="nationality" className="form-control required" defaultValue={myProfile.candidate_nationality} data-error="Please select nationality">
                                                        <option value="">Select</option>
                                                        {
                                                            nationality.map((c, k) =>
                                                                <option value={c.nationality_id} key={k}>
                                                                    {c.nationality_name}
                                                                </option>
                                                            )
                                                        }
                                                    </select>
                                                    <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Nationality</span>
                                                </label>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                {/*<label className="mb-3 top-label">
                                                    <input id="passport_number" type="text" className="form-control required" data-error="Please enter passport number" defaultValue={myProfile.candidate_passport_details} />
                                                    <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Passport number</span>
                                                </label>

                                                <label className="mb-3 top-label">
                                                    <input id="passport_expiry_date" type="date" defaultValue={myProfile.candidate_passport_expiry} className="form-control required" data-error="Please enter passport expiry date" />
                                                    <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Passport Expiry Date</span>
                                                    </label>*/}

                                                <label className="mb-3 top-label">
                                                    <textarea id="present_address" className="form-control required" style={{ height: 140 }} data-error="Please enter present address" defaultValue={myProfile.candidate_address}>{myProfile.candidate_address}</textarea>
                                                    <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Present address</span>
                                                </label>

                                                <label className="mb-3 top-label">
                                                    <textarea id="present_address_passport" className="form-control required" style={{ height: 140 }} data-error="Please enter permanent address" defaultValue={myProfile.candidate_passport_address}></textarea>
                                                    <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Permanent address as in passport</span>
                                                </label>

                                                <label className="mb-3 top-label">
                                                    <input type='text' id="birth_place" className="form-control required" data-error="Please enter birth place" defaultValue={myProfile.candidate_birth_place ?? ""} />
                                                    <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'capitalize' }}>Birth Place</span>
                                                </label>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='card mb-5 mt-5'>
                                    <div className='card-body'>

                                        <h3 className="mb-2 pb-0 display-6" id="title">Educational Details</h3><hr />

                                        {
                                            myProfile.candidate_degree == 'STUDENT' && candidateSchools.length == 0 &&
                                            <div id="schools_div">
                                                <h3 class="mb-5 mt-5 pb-0 display-8" id="title">School Details [ <a href="javascript:void(0);" onClick={() => plusSchool()}></a> ]</h3>
                                                <div className='row' id="schools">
                                                    <div className="col-sm-2 mb-3 top-label">
                                                        <input type="text" id="school_start" className="form-control required date-picker" data-error="Please select shool starting year" name="school_start[]" />
                                                        <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>School starting month & year </span>
                                                    </div>

                                                    <div className="col-sm-2 mb-3 top-label">
                                                        <input type="text" id="school_end" className="form-control required date-picker" data-error="Please select shool ending year" name="school_end[]" />
                                                        <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>School Ending month & year </span>
                                                    </div>

                                                    <div className="col-sm-1 mb-3 top-label">
                                                        <input type="text" id="class_from" className="form-control required" data-error="Please enter class From" name="class_from[]" />
                                                        <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Class From</span>
                                                    </div>

                                                    <div className="col-sm-1 mb-3 top-label">
                                                        <input type="text" id="class_to" className="form-control required" data-error="Please enter class To" name="class_to[]" />
                                                        <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Class To</span>
                                                    </div>

                                                    <div className="col-sm-3 mb-3 top-label">
                                                        <input type="text" id="school" className="form-control required" data-error="Please enter school name" name="school[]" />
                                                        <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>School</span>
                                                    </div>

                                                    <label className="col-sm-3 mb-3 top-label">
                                                        <select name="school_country[]" id="sccountry" className="form-control required" data-error="Please select country">
                                                            <option value="">Select</option>
                                                            {
                                                                countries.map((c, k) =>
                                                                    <option value={c.country_id} key={k}>
                                                                        {c.country_name}
                                                                    </option>
                                                                )
                                                            }
                                                        </select>
                                                        <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Country</span>
                                                    </label>
                                                </div>

                                                {
                                                    schoolsArr.map((d, k) =>
                                                        <div className='row'>{Parser(d)}</div>
                                                    )
                                                }

                                            </div>
                                        }


                                        {
                                            myProfile.candidate_degree == 'STUDENT' && candidateSchools.length > 0 &&

                                            <div id="schools_div">
                                                <h3 class="mb-5 mt-5 pb-0 display-8" id="title">School Details <a href="javascript:void(0);" onClick={() => plusSchool()}></a></h3>
                                                {candidateSchools.map((c, k) =>
                                                    <div className='row' id="schools">
                                                        <div className="col-sm-2 mb-3 top-label">
                                                            <input defaultValue={c.school_start_f} type="text" id="school_start" className="form-control required date-picker" data-error="Please select shool starting year" name="school_start[]" />
                                                            <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Starting month & year </span>
                                                        </div>

                                                        <div className="col-sm-2 mb-3 top-label">
                                                            <input defaultValue={c.school_end_f} type="text" id="school_end" className="form-control required date-picker" data-error="Please select shool ending year" name="school_end[]" />
                                                            <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Ending month & year </span>
                                                        </div>

                                                        <div className="col-sm-1 mb-3 top-label">
                                                            <input defaultValue={c.class_from} type="text" id="class_from" className="form-control required" data-error="Please enter class From" name="class_from[]" />
                                                            <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Class From</span>
                                                        </div>

                                                        <div className="col-sm-1 mb-3 top-label">
                                                            <input defaultValue={c.class_to} type="text" id="class_to" className="form-control required" data-error="Please enter class To" name="class_to[]" />
                                                            <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Class To</span>
                                                        </div>

                                                        <div className="col-sm-3 mb-3 top-label">
                                                            <input defaultValue={c.school} type="text" id="school" className="form-control required" data-error="Please enter school name" name="school[]" />
                                                            <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>School</span>
                                                        </div>

                                                        <label className="col-sm-3 mb-3 top-label">
                                                            <select defaultValue={c.country_f} name="school_country[]" id="sccountry" className="form-control required" data-error="Please select country">
                                                                <option value="">Select</option>
                                                                {
                                                                    countries.map((c, k) =>
                                                                        <option value={c.country_id} key={k}>
                                                                            {c.country_name}
                                                                        </option>
                                                                    )
                                                                }
                                                            </select>
                                                            <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Country</span>
                                                        </label>
                                                    </div>
                                                )}
                                                {
                                                    schoolsArr.map((d, k) =>
                                                        <div className='row'>{Parser(d)}</div>
                                                    )
                                                }

                                            </div>


                                        }


                                        <h3 class="mb-5 mt-5 pb-0 display-8" id="title">Higher Secondary Course  Details</h3>
                                        <div className="row">
                                            <label className="col-sm-4 mb-3 top-label">
                                                <input type="text" id="hs_start" className="form-control required date-picker" data-error="Higher secondary starting month & year" defaultValue={myProfile.candidate_education_details.course_start_f} />
                                                <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Higher secondary course starting month & year </span>
                                            </label>

                                            <label className="col-sm-4 mb-3 top-label">
                                                <input type="text" id="hs_end" className="form-control required date-picker" data-error="Higher secondary ending month & year" defaultValue={myProfile.candidate_education_details.course_end_f} />
                                                <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Higher secondary course ending month & year</span>
                                            </label>

                                            <label className="col-sm-4 mb-3 top-label">
                                                <input type="text" id="hs_school" className="form-control required" data-error="Please enter higher secondary school name" defaultValue={myProfile.candidate_education_details.school_name} />
                                                <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Higher secondary school name</span>
                                            </label>

                                            <label className="col-sm-4 mb-3 top-label">
                                                <input type="text" id="hs_universiry" className="form-control required" data-error="Enter your University/ Board name" defaultValue={myProfile.candidate_education_details.board_name} />
                                                <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Enter your University/ Board name</span>
                                            </label>

                                            <label className="col-sm-4 mb-3 top-label">
                                                <input type="text" id="hs_state" className="form-control required" data-error="Enter state" defaultValue={myProfile.candidate_education_details.state} />
                                                <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Enter state</span>
                                            </label>


                                            <label className="col-sm-4 mb-3 top-label">
                                                <select name="hs_country" id="hs_country" className="form-control required" data-error="Please select country">
                                                    <option value="">Select</option>
                                                    {
                                                        countries.map((c, k) =>
                                                            <option value={c.country_id} key={k}>
                                                                {c.country_name}
                                                            </option>
                                                        )
                                                    }
                                                </select>
                                                <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Country</span>
                                            </label>

                                        </div>


                                        {
                                            myProfile.candidate_degree == 'STUDENT' && candidateCollege.length == 0 &&
                                            <>
                                                <h3 class="mb-5 mt-5 pb-0 display-8" id="title">College Course  Details</h3>
                                                <div className='row'>

                                                    <label className="col-sm-4 mb-3 top-label">
                                                        <input autocomplete="off" type="text" name="college_start[]" id="lc_start" className="form-control date-picker" data-error="College course start date" defaultValue={myProfile.candidate_language_course.lc_start} />
                                                        <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'capitalize' }}>Start Date</span>
                                                    </label>

                                                    <div className="col-sm-4 mb-3">
                                                        <label className='top-label'>
                                                            <input autocomplete="off" type="text" name="college_end[]" id="lc_end" className="form-control date-picker" data-error="Language course end date" defaultValue={myProfile.candidate_language_course.lc_end} />
                                                            <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'capitalize' }}>End Date</span>
                                                        </label>
                                                        <label style={{ display: 'none' }}><input type='checkbox' name="till_now[]" id="till_now" value={1} /> Till Now</label>
                                                    </div>


                                                    <label className="col-sm-4 mb-3 top-label">
                                                        <input type="text" name="college_school[]" id="college_school[]" className="form-control" data-error="Enter your school name" defaultValue={myProfile.candidate_language_course.lc_school} />
                                                        <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'capitalize' }}>College name</span>
                                                    </label>

                                                    <label className="col-sm-4 mb-3 top-label">
                                                        <input type="text" name="college_course[]" id="college_course[]" className="form-control" data-error="Enter your school name" defaultValue={myProfile.candidate_language_course.lc_school} />
                                                        <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'capitalize' }}>Course name</span>
                                                    </label>

                                                    <label className="col-sm-4 mb-3 top-label">
                                                        <input type="text" name="college_state[]" id="college_state" className="form-control" data-error="Enter state" defaultValue={myProfile.candidate_language_course.lc_state} />
                                                        <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'capitalize' }}>State</span>
                                                    </label>

                                                    <label className="col-sm-4 mb-3 top-label">
                                                        <select name="college_country[]" id="college_country" className="form-control" data-error="Please select country">
                                                            <option value="">Select</option>
                                                            {
                                                                countries.map((c, k) =>
                                                                    <option value={c.country_id} key={k}>
                                                                        {c.country_name}
                                                                    </option>
                                                                )
                                                            }
                                                        </select>
                                                        <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'capitalize' }}>Country</span>
                                                    </label>
                                                </div>
                                                <hr />
                                                <div className='row'>

                                                    <label className="col-sm-4 mb-3 top-label">
                                                        <input autocomplete="off" type="text" name="college_start[]" id="lc_start" className="form-control date-picker" data-error="College course start date" defaultValue={myProfile.candidate_language_course.lc_start} />
                                                        <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'capitalize' }}>Start Date</span>
                                                    </label>

                                                    <div className="col-sm-4 mb-3">
                                                        <label className='top-label'>
                                                            <input autocomplete="off" type="text" name="college_end[]" id="lc_end" className="form-control date-picker" data-error="Language course end date" defaultValue={myProfile.candidate_language_course.lc_end} />
                                                            <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'capitalize' }}>End Date</span>
                                                        </label>
                                                        <label style={{ display: 'none' }}><input type='checkbox' name="till_now[]" id="till_now" value={1} /> Till Now</label>
                                                    </div>


                                                    <label className="col-sm-4 mb-3 top-label">
                                                        <input type="text" name="college_school[]" id="college_school[]" className="form-control" data-error="Enter your school name" defaultValue={myProfile.candidate_language_course.lc_school} />
                                                        <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'capitalize' }}>College name</span>
                                                    </label>

                                                    <label className="col-sm-4 mb-3 top-label">
                                                        <input type="text" name="college_course[]" id="college_course[]" className="form-control" data-error="Enter your school name" defaultValue={myProfile.candidate_language_course.lc_school} />
                                                        <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'capitalize' }}>Course name</span>
                                                    </label>

                                                    <label className="col-sm-4 mb-3 top-label">
                                                        <input type="text" name="college_state[]" id="college_state" className="form-control" data-error="Enter state" defaultValue={myProfile.candidate_language_course.lc_state} />
                                                        <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'capitalize' }}>State</span>
                                                    </label>

                                                    <label className="col-sm-4 mb-3 top-label">
                                                        <select name="college_country[]" id="college_country" className="form-control" data-error="Please select country">
                                                            <option value="">Select</option>
                                                            {
                                                                countries.map((c, k) =>
                                                                    <option value={c.country_id} key={k}>
                                                                        {c.country_name}
                                                                    </option>
                                                                )
                                                            }
                                                        </select>
                                                        <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'capitalize' }}>Country</span>
                                                    </label>
                                                </div>
                                            </>
                                        }


                                        {myProfile.candidate_degree == 'STUDENT' && candidateCollege.length > 0 &&
                                            <>
                                                <h3 class="mb-5 mt-5 pb-0 display-8" id="title">College Course  Details</h3>
                                                {candidateCollege.map((c, k) =>
                                                    <div className='row'>

                                                        <label className="col-sm-4 mb-3 top-label">
                                                            <input autocomplete="off" type="text" name="college_start[]" id="lc_start" className="form-control date-picker" data-error="College course start date" defaultValue={c.course_start} />
                                                            <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'capitalize' }}>Start Date</span>
                                                        </label>

                                                        <div className="col-sm-4 mb-3">
                                                            <label className='top-label'>
                                                                <input autocomplete="off" type="text" name="college_end[]" id="lc_end" className="form-control date-picker" data-error="College course end date" defaultValue={c.course_end} />
                                                                <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'capitalize' }}>End Date</span>
                                                            </label>
                                                            <label style={{ display: 'none' }}><input type='checkbox' name="till_now[]" id="till_now" value={1} /> Till Now</label>
                                                        </div>


                                                        <label className="col-sm-4 mb-3 top-label">
                                                            <input type="text" name="college_school[]" id="college_school[]" className="form-control" data-error="Enter your college name" defaultValue={c.college_school} />
                                                            <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'capitalize' }}>College name</span>
                                                        </label>

                                                        <label className="col-sm-4 mb-3 top-label">
                                                            <input type="text" name="college_course[]" id="college_course[]" className="form-control" data-error="Enter your couyrtse name" defaultValue={c.college_course} />
                                                            <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'capitalize' }}>Course name</span>
                                                        </label>

                                                        <label className="col-sm-4 mb-3 top-label">
                                                            <input type="text" name="college_state[]" id="college_state" className="form-control" data-error="Enter state" defaultValue={c.college_state} />
                                                            <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'capitalize' }}>State</span>
                                                        </label>

                                                        <label className="col-sm-4 mb-3 top-label">
                                                            <select name="college_country[]" id="college_country" className="form-control" data-error="Please select country" defaultValue={c.country}>
                                                                <option value="">Select</option>
                                                                {
                                                                    countries.map((co, key) =>
                                                                        <option value={co.country_id} key={key}>
                                                                            {co.country_name}
                                                                        </option>
                                                                    )
                                                                }
                                                            </select>
                                                            <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'capitalize' }}>Country</span>
                                                        </label>
                                                    </div>
                                                )}
                                            </>
                                        }


                                        {
                                            myProfile.candidate_degree != 'STUDENT' &&
                                            <>
                                                <div class="d-flex justify-content-between">
                                                    <h3 class="mb-5 mt-5 pb-0 display-8" id="title">Nursing Course Details</h3>
                                                    <div className='mb-5 mt-5 pb-0'>
                                                        <a href="javascript:void(0);" onClick={() => plusCourse()}>Add New Course + </a>
                                                    </div>
                                                </div>
                                                {
                                                    myProfile.candidate_nurse_course.length > 0 &&

                                                    myProfile.candidate_nurse_course.map((c, k) =>
                                                        <div className='row'>

                                                            <label className="col-sm-4 mb-3 top-label">
                                                                <input type="text" name="nc_start[]" id="nc_start" className="form-control required date-picker" data-error="Nursing starting month & year" defaultValue={c.course_start} />
                                                                <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Nursing course starting month & year</span>
                                                            </label>

                                                            <label className="col-sm-4 mb-3 top-label">
                                                                <input type="text" name="nc_end[]" id="nc_end" className="form-control required date-picker" data-error="Higher secondary ending month & year" defaultValue={c.course_end} />
                                                                <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Nursing course ending month & year</span>
                                                            </label>

                                                            <label className="col-sm-4 mb-3 top-label">
                                                                <select name="nc_course[]" className="form-control required" id="nc_course" data-error="Please select nursing course" defaultValue={c.course}>
                                                                    <option value="">Select</option>
                                                                    <option value="1">B.Sc Nursing 4 years course</option>
                                                                    <option value="2">M.Sc Nursing 2 years course</option>
                                                                    <option value="3">Diploma in Nursing/GNM 3.5 years course</option>
                                                                    <option value="4">Post Basic B.Sc 2 years course</option>
                                                                    <option value="5">Diploma in Nursing/GNM 3 years course</option>
                                                                </select>
                                                                <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Nursing Course</span>
                                                            </label>

                                                            <label className="col-sm-4 mb-3 top-label">
                                                                <input type="text" name="nc_college[]" id="nc_college" className="form-control required" data-error="Enter your college" defaultValue={c.college_name} />
                                                                <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Enter your college name</span>
                                                            </label>

                                                            <label className="col-sm-4 mb-3 top-label">
                                                                <input type="text" name="nc_university[]" id="nc_universiry" className="form-control required" data-error="Enter your University/ Board name" defaultValue={c.board_name} />
                                                                <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Enter your University/ Board name</span>
                                                            </label>

                                                            <label className="col-sm-4 mb-3 top-label">
                                                                <input type="text" name="nc_state[]" id="nc_state" className="form-control required" data-error="Enter state" defaultValue={c.state} />
                                                                <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Enter state</span>
                                                            </label>


                                                            <label className="col-sm-4 mb-3 top-label">
                                                                <select name="nc_country[]" id="nc_country" className="form-control required" data-error="Please select country" defaultValue={c.country}>
                                                                    <option value="">Select</option>
                                                                    {
                                                                        countries.map((c, k) =>
                                                                            <option value={c.country_id} key={k}>
                                                                                {c.country_name}
                                                                            </option>
                                                                        )
                                                                    }
                                                                </select>
                                                                <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Country</span>
                                                            </label>
                                                            <hr />
                                                        </div>
                                                    )


                                                }

                                                {
                                                    myProfile.candidate_nurse_course.length == 0 &&
                                                    <div className='row'>

                                                        <label className="col-sm-4 mb-3 top-label">
                                                            <input type="text" name="nc_start[]" id="nc_start" className="form-control required date-picker" data-error="Nursing starting month & year" />
                                                            <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Nursing course starting month & year</span>
                                                        </label>

                                                        <label className="col-sm-4 mb-3 top-label">
                                                            <input type="text" name="nc_end[]" id="nc_end" className="form-control required date-picker" data-error="Higher secondary ending month & year" />
                                                            <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Nursing course ending month & year</span>
                                                        </label>

                                                        <label className="col-sm-4 mb-3 top-label">
                                                            <select name="nc_course[]" className="form-control required" id="nc_course" data-error="Please select nursing course">
                                                                <option value="">Select</option>
                                                                <option value="1">B.Sc Nursing 4 years course</option>
                                                                <option value="2">M.Sc Nursing 2 years course</option>
                                                                <option value="3">Diploma in Nursing/GNM 3.5 years course</option>
                                                                <option value="5">Diploma in Nursing/GNM 3 years course</option>
                                                                <option value="4">Post Basic B.Sc 2 years course</option>
                                                            </select>
                                                            <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Nursing Course</span>
                                                        </label>

                                                        <label className="col-sm-4 mb-3 top-label">
                                                            <input type="text" name="nc_college[]" id="nc_college" className="form-control required" data-error="Enter your college" />
                                                            <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Enter your college name</span>
                                                        </label>

                                                        <label className="col-sm-4 mb-3 top-label">
                                                            <input type="text" name="nc_university[]" id="nc_universiry" className="form-control required" data-error="Enter your University/ Board name" />
                                                            <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Enter your University/ Board name</span>
                                                        </label>

                                                        <label className="col-sm-4 mb-3 top-label">
                                                            <input type="text" name="nc_state[]" id="nc_state" className="form-control required" data-error="Enter state" />
                                                            <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Enter state</span>
                                                        </label>


                                                        <label className="col-sm-4 mb-3 top-label">
                                                            <select name="nc_country[]" id="nc_country" className="form-control required" data-error="Please select country">
                                                                <option value="">Select</option>
                                                                {
                                                                    countries.map((c, k) =>
                                                                        <option value={c.country_id} key={k}>
                                                                            {c.country_name}
                                                                        </option>
                                                                    )
                                                                }
                                                            </select>
                                                            <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Country</span>
                                                        </label>
                                                        <hr />
                                                    </div>
                                                }

                                                {courseArr.map((d, k) =>
                                                    <div className='row'>
                                                        <div className='col-sm-12 text-end mb-2'><a href="javascript:void(0);" onClick={() => _remove(k)}>Remove</a></div>
                                                        {Parser(d)}
                                                    </div>
                                                )}
                                            </>
                                        }


                                    </div>
                                </div>

                                <div className='card mb-5 mt-5'>
                                    <div className='card-body'>
                                        <div className='d-flex justify-content-between'>
                                            <h3 className="mb-2 pb-0 display-6" id="title">German Language Course</h3>
                                        </div>

                                        <hr />

                                        <div className='row'>
                                            <label className="col-sm-4 mb-3 top-label">
                                                <select name="lc_language_level[]" id="lc_language_level" className="form-control" data-error="Please select language level">
                                                    <option value="">Select</option>
                                                    {
                                                        languageCourseLevels.map((c, k) =>
                                                            <option value={c.id} key={k}>{c.val}</option>
                                                        )
                                                    }
                                                </select>
                                                <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'capitalize' }}>Current Level</span>
                                            </label>

                                            <label className="col-sm-4 mb-3 top-label">
                                                <input type="text" name="lc_start[]" id="lc_start" className="form-control date-picker" data-error="Language course start date" defaultValue={myProfile.candidate_language_course.lc_start} />
                                                <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'capitalize' }}>Start Date</span>
                                            </label>

                                            <div className="col-sm-4 mb-3">
                                                <label className='top-label'>
                                                    <input type="text" name="lc_end[]" id="lc_end" className="form-control date-picker" data-error="Language course end date" defaultValue={myProfile.candidate_language_course.lc_end} />
                                                    <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'capitalize' }}>End Date</span>
                                                </label>
                                                <label style={{ display: 'none' }}><input type='checkbox' name="till_now[]" id="till_now" value={1} /> Till Now</label>
                                            </div>


                                            <label className="col-sm-4 mb-3 top-label">
                                                <input type="text" name="lc_school[]" id="lc_school" className="form-control" data-error="Enter your school name" defaultValue={myProfile.candidate_language_course.lc_school} />
                                                <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'capitalize' }}>School name</span>
                                            </label>

                                            <label className="col-sm-4 mb-3 top-label">
                                                <input type="text" name="lc_state[]" id="lc_state" className="form-control" data-error="Enter state" defaultValue={myProfile.candidate_language_course.lc_state} />
                                                <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'capitalize' }}>State</span>
                                            </label>

                                            <label className="col-sm-4 mb-3 top-label">
                                                <select name="lc_country[]" id="lc_country" className="form-control" data-error="Please select country">
                                                    <option value="">Select</option>
                                                    {
                                                        countries.map((c, k) =>
                                                            <option value={c.country_id} key={k}>
                                                                {c.country_name}
                                                            </option>
                                                        )
                                                    }
                                                </select>
                                                <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'capitalize' }}>Country</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>


                                {
                                    <div className='card mb-5 mt-5'>
                                        <div className='card-body'>
                                            <h3 className="mb-2 pb-0 display-6" id="title">Professional experience</h3><hr />

                                            <div className='row'>
                                                <div className=" col-sm-12 form-group">

                                                    <label className="mb-3 top-label">
                                                        <select className="form-control required" id="nc_total_experience" data-error="Please select total year experience">
                                                            <option value="">Select</option>
                                                            <option value="0">Less than 1 year</option>
                                                            {experiences()}
                                                            <option value="21">21+ Years</option>
                                                        </select>
                                                        <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Select your total years of experience</span>
                                                    </label>

                                                </div>
                                            </div>

                                            {experienceDetails()}

                                        </div>
                                    </div>
                                }
                                <div className='card mb-5 mt-5'>
                                    <div className='card-body'>
                                        <h3 className="mb-2 pb-0 display-6" id="title">Language Skills</h3><hr />
                                        <div className='row'>
                                            <div className=" col-sm-8">
                                                <label className="w-100 mb-3 top-label">
                                                    <input type="text" name="mother_tongue" id="mother_tongue" className="form-control" data-error="Enter mother tongue" defaultValue={myProfile.candidate_mother_tongue} />
                                                    <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'capitalize' }}>Mother Tongue</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className=" col-sm-4 form-group d-none">
                                                <label className="mb-3 top-label">
                                                    <select className="form-control" id="language" data-error="Please select language">
                                                        <option value="">Select</option>
                                                        {
                                                            speaking.map((s, k) =>
                                                                <option value={s.speaking_id}>{s.speaking_name}</option>
                                                            )
                                                        }
                                                    </select>
                                                    <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Select language</span>
                                                </label>
                                            </div>
                                            <div className=" col-sm-4 form-group d-none">
                                                <label className="mb-3 top-label">
                                                    <select className="form-control" id="language_level" data-error="Please select total year experience">
                                                        <option value="">Select</option>
                                                        <option value="A1 - beginner level">A1 - beginner level</option>
                                                        <option value="A2 - elementary level">A2 - elementary level</option>
                                                        <option value="B1 - intermediate level">B1 - intermediate level</option>
                                                        <option value="B2 - upper intermediate level">B2 - upper intermediate level</option>
                                                        <option value="C1 - advanced level">C1 - advanced level</option>
                                                        <option value="C2 - highly competent level">C2 - highly competent level</option>
                                                    </select>
                                                    <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Select your level</span>
                                                </label>
                                            </div>
                                            <div className=" col-sm-4 form-group d-none">
                                                <button onClick={() => _addLanguageSkills()} type="button" className="btn btn-outline-primary btn-icon btn-icon-start w-100 w-md-auto" data-bs-toggle="modal" data-bs-target="#largeRightModalExample">
                                                    <i data-acorn-icon="plus"></i>
                                                    <span>Add</span>
                                                </button>
                                            </div>

                                            <div style={{ clear: 'both' }}>&nbsp;</div>
                                            {
                                                languageSkills.map((l, k) =>
                                                    <div className='col-sm-3 form-group'>
                                                        {l.name}<br />
                                                        <span style={{ color: "rgb(142, 141, 141)" }}>{l.level}</span>
                                                    </div>
                                                )
                                            }
                                        </div>

                                        <h3 className="mb-2 pb-0 display-6 mt-4 pt-4" id="title">Additional Note</h3><hr />
                                        <div className='row'>
                                            <div className='col-sm-12'>
                                                <textarea id="additional_note" className='form-control' placeholder='Additional Note'></textarea>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="d-grid gap-2 mb-5 mt-5">
                                    <button onClick={(e) => save(e)} className="btn btn-primary" type="button">Save</button>
                                </div>

                                <div className='card'>
                                    <div className='card-body'>
                                        <h2 class="small-title" style={{ margin: 0 }}>Note</h2>
                                        <div className="col-sm-12 col-md-12 col-lg-12 col-xxl-12 mb-1">
                                            <div className='card mb-5'>
                                                <div className='card-body p0' style={{ padding: 0 }}>
                                                    <textarea className='form-control' id="note" style={{ height: 100 }} defaultValue={myProfile.candidate_note}></textarea><br />
                                                    <button class="btn btn-primary" type="button" onClick={() => _addNote()}>Add Note</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/*<div className='card mb-5 mt-5'>
                                        <div className='card-body'>
                                            <h3 className="mb-2 pb-0 display-6" id="title">Hobbies</h3><hr />
                                            <div className='row'>
                                                {
                                                    hobbies.map((h, k) =>
                                                        <div className=" col-sm-3 form-group">
                                                            <div className="form-check mt-2">
                                                                <input type="checkbox" className="hobbies form-check-input" value={h.hobby_id} id={"hb_" + h.hobby_id} />
                                                                <label className="form-check-label" htmlFor={"hb_" + h.hobby_id} style={{ textTransform: 'capitalize' }}>{h.hobby_name}</label>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                            </div>*/}

                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </>
    )
}



// Convert IDs to Names
function getDepartmentNames(rawValues, departments) {
    // Handle single ID or comma-separated IDs
    const deptIds = rawValues.includes(',') ? 
        rawValues.split(',').map(item => item.trim()) : 
        [rawValues];

    // Create ID to Name mapping
    const departmentMap = departments.reduce((map, dept) => {
        map[dept.department_id] = dept.department_name;
        return map;
    }, {});

    // Convert IDs to names and join
    const deptNames = deptIds
        .map(id => departmentMap[id] || '')
        .filter(Boolean)
        .join(', ');

    return deptNames;
}

// Convert Names to IDs
function getDepartmentIds(departmentNames, departments) {
    // Handle string or array input
    const deptNamesArray = Array.isArray(departmentNames) ? 
        departmentNames : 
        departmentNames.split(',').map(name => name.trim());

    // Create Name to ID mapping
    const nameToIdMap = departments.reduce((map, dept) => {
        map[dept.department_name] = dept.department_id;
        return map;
    }, {});

    // Convert names to IDs and join
    const deptIds = deptNamesArray
        .map(name => nameToIdMap[name])
        .filter(Boolean)
        .join(',');

    return deptIds;
}

export default withParams(CandidateProfile);