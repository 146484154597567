import React from 'react';
import {
  BrowserRouter,
  Routes,
  Navigate, Outlet
} from "react-router-dom";

import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Cookies from 'js-cookie';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import Login from './pages/Login';
import Logout from './pages/Logout';

import Index from './pages/dashboard/Index';
import Profile from './pages/dashboard/Profile';
import Documents from './pages/documents/Index';

import Candidates from './pages/candidates/Index';
import CandidateProfile from './pages/candidates/Profile';
import CandidateDocuments from './pages/candidates/Documents';
import CandidatesLicensed from './pages/candidates/Licensed';
import CandidatesInactive from './pages/candidates/Inactive';
import Recommended from './pages/candidates/Recommended';
import Campaigns from './pages/candidates/Campaigns';

import SettingsSite from './pages/settings/Site';
import SettingsHobbies from './pages/settings/Hobbies';
import SettingsDesignations from './pages/settings/Designations';
import SettingsDepartments from './pages/settings/Departments';
import SettingsCountries from './pages/settings/Countries';
import SettingsNationality from './pages/settings/Nationalities';
import SettingsSpeakingLanguages from './pages/settings/SpeakingLanguages';
import Contacts from './pages/settings/Contacts';

import Translators from './pages/translators/Index';
import TranslatorDetails from './pages/translators/Details';

import TranslatorMyAccount from './pages/translator/myaccount/Index';
import TranslatorLogin from './pages/translator/login/Index';
import TranslatorLogout from './pages/translator/login/Logout';
import TranslatorDashboard from './pages/translator/dashboard/Index';
import TranslatorCandidates from './pages/translator/dashboard/Candidates';
import TranslatorCandidateDocuments from './pages/translator/dashboard/CandidateDocuments';
import TranslatorDocuments from './pages/translator/documents/Index';

import Employers from './pages/employers/Index';
import Enquiries from './pages/employers/Enquiries';
import EnquiryDetails from './pages/employers/EnquiryDetails';
import Scheduled from './pages/employers/Scheduled';
import Rejected from './pages/employers/Rejected';
import Selected from './pages/employers/Selected';
import SelectedDetails from './pages/employers/SelectedDetails';

import EmployerDashboard from './pages/employer/dashboard/Index';
import EmployerLogin from './pages/employer/login/Index';
import EmployerLogout from './pages/employer/login/Logout';
import EmployerEnquiriesGenerate from './pages/employer/enquiries/Index';
import EmployerEnquiryDetails from './pages/employer/enquiries/Details';
import EmployerCandidateDetails from './pages/employer/candidate/Index';
import EmployerSelected from './pages/employer/candidate/Selected';
import EmployerRejected from './pages/employer/candidate/Rejected';
import EmployerNotAttend from './pages/employer/candidate/NotAttend';

import Staffs from './pages/staffs/Index';

import Affiliates from './pages/affiliates/Index';
import AffiliatorDetails from './pages/affiliates/Details';
import AffiliatorLogin from './pages/affiliates/Login';
import AffiliatorCandidates from './pages/affiliator/Candidates';
import AffiliatorProfile from './pages/affiliator/Profile';
import AffiliatorLogout from './pages/affiliator/Logout';

export const fakeAuthAdmin = {
  isAuthenticated: false,

  authenticate() {
    if (Cookies.get('hcAdmin') == undefined) {
      this.isAuthenticated = false;
    }
    else {
      this.isAuthenticated = true;
    }

    return this.isAuthenticated;
  },
  signout() {
    this.isAuthenticated = false
    //setTimeout(cb, 100) // fake async
  }
}

export const fakeAuthTranslator = {
  isAuthenticated: false,

  authenticate() {
    if (Cookies.get('hcTransilator') == undefined) {
      this.isAuthenticated = false;
    }
    else {
      this.isAuthenticated = true;
    }

    return this.isAuthenticated;
  },
  signout() {
    this.isAuthenticated = false
    //setTimeout(cb, 100) // fake async
  }
}

const fakeAuthEmployer = {
  isAuthenticated: false,

  authenticate() {
    if (Cookies.get('hcEmployer') == undefined) {
      this.isAuthenticated = false;
    }
    else {
      this.isAuthenticated = true;
    }

    return this.isAuthenticated;
  },
  signout() {
    this.isAuthenticated = false
    //setTimeout(cb, 100) // fake async
  }
}

const fakeAuthAffiliator = {
  isAuthenticated: false,

  authenticate() {
    if (Cookies.get('hcAffiliator') == undefined) {
      this.isAuthenticated = false;
    }
    else {
      this.isAuthenticated = true;
    }

    return this.isAuthenticated;
  },
  signout() {
    this.isAuthenticated = false
    //setTimeout(cb, 100) // fake async
  }
}


const PrivateRoute = ({ children }) => {
  const authed = fakeAuthTranslator.authenticate() // isauth() returns true or false based on localStorage
  return authed ? children : <Navigate to="/translator/login" />;
}

const PrivateRouteAdmin = ({ children }) => {
  const authed = fakeAuthAdmin.authenticate() // isauth() returns true or false based on localStorage
  return authed ? children : <Navigate to="/login" />;
}

const PrivateRouteEmployer = ({ children }) => {
  const authed = fakeAuthEmployer.authenticate() // isauth() returns true or false based on localStorage
  return authed ? children : window.location = "https://hireandcare.de/login";
}

const PrivateRouteAffiliator = ({ children }) => {
  const authed = fakeAuthAffiliator.authenticate() // isauth() returns true or false based on localStorage
  return authed ? children : window.location = "https://hireandcare.de/affiliator/login";
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/" element={<PrivateRouteAdmin><Candidates /></PrivateRouteAdmin>} />
      <Route path="/profile" element={<PrivateRouteAdmin><Profile /></PrivateRouteAdmin>} />
      <Route path="/logout" element={<PrivateRouteAdmin><Logout /></PrivateRouteAdmin>} />
      <Route path="/documents" element={<PrivateRouteAdmin><Documents /></PrivateRouteAdmin>} />
      <Route path="/candidates" element={<PrivateRouteAdmin><Candidates /></PrivateRouteAdmin>} />
      <Route path="/candidates/inactive" element={<PrivateRouteAdmin><CandidatesInactive /></PrivateRouteAdmin>} />
      <Route path="/candidates/recommended" element={<PrivateRouteAdmin><Recommended /></PrivateRouteAdmin>} />
      <Route path="/candidates/campaigns" element={<PrivateRouteAdmin><Campaigns /></PrivateRouteAdmin>} />
      <Route path="/candidates/:type" element={<PrivateRouteAdmin><CandidatesLicensed /></PrivateRouteAdmin>} />
      <Route path="/candidate/:id" element={<PrivateRouteAdmin><CandidateProfile /></PrivateRouteAdmin>} />
      <Route path="/candidate/documents/:id" element={<PrivateRouteAdmin><CandidateDocuments /></PrivateRouteAdmin>} />
      <Route path="/settings/site" element={<PrivateRouteAdmin><SettingsSite /></PrivateRouteAdmin>} />
      <Route path="/settings/hobbies" element={<PrivateRouteAdmin><SettingsHobbies /></PrivateRouteAdmin>} />
      <Route path="/settings/designations" element={<PrivateRouteAdmin><SettingsDesignations /></PrivateRouteAdmin>} />
      <Route path="/settings/departments" element={<PrivateRouteAdmin><SettingsDepartments /></PrivateRouteAdmin>} />
      <Route path="/settings/countries" element={<PrivateRouteAdmin><SettingsCountries /></PrivateRouteAdmin>} />
      <Route path="/settings/nationalities" element={<PrivateRouteAdmin><SettingsNationality /></PrivateRouteAdmin>} />
      <Route path="/settings/speaking-languages" element={<PrivateRouteAdmin><SettingsSpeakingLanguages /></PrivateRouteAdmin>} />
      <Route path="/translators" element={<PrivateRouteAdmin><Translators /></PrivateRouteAdmin>} />

      <Route path="/translator/myaccount" element={<PrivateRoute><TranslatorMyAccount /></PrivateRoute>} />
      <Route path="/translator/logout" element={<PrivateRoute><TranslatorLogout /></PrivateRoute>} />
      <Route path="/translator/:id" element={<PrivateRouteAdmin><TranslatorDetails /></PrivateRouteAdmin>} />
      <Route path="/translator/login" element={<TranslatorLogin />} />
      <Route path="/translator" element={<PrivateRoute><TranslatorCandidates /></PrivateRoute>} />
      <Route path="/translator/dashboard" element={<PrivateRoute><TranslatorDashboard /></PrivateRoute>} />
      <Route path="/translator/candidates" element={<PrivateRoute><TranslatorCandidates /></PrivateRoute>} />
      <Route path="/translator/candidate/documents/:id" element={<PrivateRoute><TranslatorCandidateDocuments /></PrivateRoute>} />
      <Route path="/translator/documents/:type" element={<PrivateRoute><TranslatorDocuments /></PrivateRoute>} />

      <Route path="/employers" element={<PrivateRouteAdmin><Employers /></PrivateRouteAdmin>} />
      <Route path="/employers/interview_scheduled" element={<PrivateRouteAdmin><Scheduled /></PrivateRouteAdmin>} />
      <Route path="/employers/rejected" element={<PrivateRouteAdmin><Rejected /></PrivateRouteAdmin>} />
      <Route path="/employers/selected/candidate/:employer_id/:candidate_id" element={<PrivateRouteAdmin><SelectedDetails /></PrivateRouteAdmin>} />
      <Route path="/employers/selected" element={<PrivateRouteAdmin><Selected /></PrivateRouteAdmin>} />
      <Route path="/employer/enquiries/:id" element={<PrivateRouteAdmin><Enquiries /></PrivateRouteAdmin>} />
      <Route path="/employer/enquiry_details/:id" element={<PrivateRouteAdmin><EnquiryDetails /></PrivateRouteAdmin>} />


      <Route path="/employer/selected" element={<PrivateRouteEmployer><EmployerSelected /></PrivateRouteEmployer>} />
      <Route path="/employer/rejected" element={<PrivateRouteEmployer><EmployerRejected /></PrivateRouteEmployer>} />
      <Route path="/employer/not_attend" element={<PrivateRouteEmployer><EmployerNotAttend /></PrivateRouteEmployer>} />
      <Route path="/employer/enquiries/generate" element={<PrivateRouteEmployer><EmployerEnquiriesGenerate /></PrivateRouteEmployer>} />
      <Route path="/employer" element={<PrivateRouteEmployer><EmployerDashboard /></PrivateRouteEmployer>} />
      <Route path="/employer/login" element={<EmployerLogin />} />
      <Route path="/employer/logout" element={<PrivateRouteEmployer><EmployerLogout /></PrivateRouteEmployer>} />
      <Route path="/employer/enquiry/:id" element={<PrivateRouteEmployer><EmployerEnquiryDetails /></PrivateRouteEmployer>} />
      <Route path="/employer/enquiry/candidate/:id" element={<PrivateRouteEmployer><EmployerCandidateDetails /></PrivateRouteEmployer>} />

      <Route path="/staffs" element={<PrivateRouteAdmin><Staffs /></PrivateRouteAdmin>} />
      <Route path="/web-contacts" element={<PrivateRouteAdmin><Contacts /></PrivateRouteAdmin>} />

      <Route path="/affiliates" element={<PrivateRouteAdmin><Affiliates /></PrivateRouteAdmin>} />
      <Route path="/affiliates/:id" element={<PrivateRouteAdmin><AffiliatorDetails /></PrivateRouteAdmin>} />

      <Route path="/affiliator" element={<AffiliatorLogin />} />
      <Route path="/affiliator/login" element={<AffiliatorLogin />} />
      <Route path="/affiliator/candidates" element={<PrivateRouteAffiliator><AffiliatorCandidates /></PrivateRouteAffiliator>} />
      <Route path="/affiliator/profile" element={<PrivateRouteAffiliator><AffiliatorProfile /></PrivateRouteAffiliator>} />
      <Route path="/affiliator/registration-url" element={<PrivateRouteAffiliator><AffiliatorProfile /></PrivateRouteAffiliator>} />
      <Route path="/affiliator/logout" element={<AffiliatorLogout />} />
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
