import React, { useState, useHook, useEffect, useRef } from "react";
import Header from "../Header";
import SideBar from "../SideBar";
import Footer from "../Footer";
import Loader from "../Loader";
import { get, post, put, deleteM } from "../../utility/Adapter";
import { withParams } from "../HOC";

import { info, success } from "@pnotify/core";
import "@pnotify/core/dist/PNotify.css";
import "@pnotify/core/dist/BrightTheme.css";
import * as Confirm from "@pnotify/confirm";
import "@pnotify/confirm/dist/PNotifyConfirm.css";
import DataTable, { createTheme } from "react-data-table-component";
import Cookies from "js-cookie";
import {
  Stethoscope,
  UserRound,
  ClipboardCheck,
  GraduationCap,
  XCircle,
  Clipboard,
} from "lucide-react";
import Toast from "../../components/Toast";

function getIconForDegree(degree) {
  switch (degree) {
    case "GNM":
      return Stethoscope;
    case "NURSE":
      return UserRound;
    case "REGISTERED_NURSE":
      return ClipboardCheck;
    case "STUDENT":
      return GraduationCap;
    default:
      return XCircle;
  }
}

const Candidates = (props) => {
  const [candidates, setCandidates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pending, setPending] = React.useState(true);
  const [totalRows, setTotalRows] = React.useState(0);
  const [paginationPerPage, setPaginationPerPage] = React.useState(25);
  //const alert = useAlert();

  const [selectedDayRange, setSelectedDayRange] = useState({
    from: null,
    to: null,
  });

  const [candidateTypeCounts, setCandidateTypeCounts] = useState({
    NONE: 0,
    GNM: 0,
    NURSE: 0,
    REGISTERED_NURSE: 0,
    STUDENT: 0,
  });

  const [hoveredPhone, setHoveredPhone] = useState(null);
  const [hoveredEmail, setHoveredEmail] = useState(null);

  const [toastMessage, setToastMessage] = useState(null);
  const [toastType, setToastType] = useState("success");

  useEffect(function () {
    _loadCandidates(1);

    window.$("document").ready(function () {
      window.$("#datePickerRange").datepicker({
        weekStart: 1,
      });
    });

    /*if (props.params.type != undefined) {
            filter('licensed');
        }*/
  }, []);

  const _loginAs = (candidate_id) => {
    get("admin/candidates/las/" + candidate_id)
      .then(async function (response) {
        if (response.data.data.token) {
          //Cookies.set('hcUser', response.data.data.token, { expires: 1 });
          Cookies.set("hcUser", response.data.data.token, {
            domain: "dashboard.hireandcare.de",
          });
          Cookies.set("hcUser", response.data.data.token, {
            domain: "hireandcare.de",
          });
          window.open(process.env.REACT_APP_DASHBOARD_URL, "_blank");
        }
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  const _loadCandidates = (page) => {
    setLoading(true);
    get("admin/candidates/" + page)
      .then(async function (response) {
        setCandidates(response.data.data);
        setTotalRows(response.data.totalRows);
        setPaginationPerPage(response.data.paginationPerPage);
        setPending(false);
        setLoading(false);

        setCandidateTypeCounts(response.data.countByCandidateType);

        var table = window.$("#datatableRows").DataTable();
        table.destroy();

        setTimeout(function () {
          var uninitialized = window.$("#datatableRows").filter(function () {
            return !window.$.fn.DataTable.fnIsDataTable(this);
          });

          uninitialized.each(function () {
            window.$(this).dataTable({
              dom: "Bfrtip",
              buttons: [
                {
                  extend: "excel",
                  text: "Export Excel",
                  className: "btn btn-light",
                  exportOptions: {
                    columns: "th:not(:last-child)",
                  },
                },
                {
                  extend: "csv",
                  text: "Export Csv",
                  className: "btn btn-light",
                  exportOptions: {
                    columns: "th:not(:last-child)",
                  },
                },
              ],
            });
          });
        }, 100);

        //window.$("#datatableRows").dataTable();
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  var documentStatus = {
    0: "Pending",
    1: "Submitted",
    2: "Waiting Proposal",
    3: "Waiting Payment",
    4: "Translation Progress",
    5: "Translation Under Review",
    6: "Completed",
  };

  var languageLevels = {
    NOT_ENTROLLED: "Not yet enrolled",
    A1_ENTROLLED: "A1 Enrolled",
    A1_COMPLETE: "A2 Complete",
    B1_COMPLETE: "B1 Complete",
    B2_COMPLETE: "B2 Complete",
    C1_COMPLETE: "C1 Complete",
    C2_COMPLETE: "C2 Complete",
  };

  var languageLevelsId = [
    { id: "ANY", val: "Any" },
    { id: "NOT_ENTROLLED", val: "Not yet enrolled" },
    { id: "A1_ENTROLLED", val: "A1 Enrolled" },
    { id: "A1_COMPLETE", val: "A2 Complete" },
    { id: "B1_COMPLETE", val: "B1 Complete" },
    { id: "B2_COMPLETE", val: "B2 Complete" },
    /*{ id: "C1_COMPLETE", val: "C1 Complete" },
    { id: "C2_COMPLETE", val: "C2 Complete" },*/
  ];

  const [selectedLanguageFilter, setSelectedLanguageFilter] = useState(languageLevelsId[0].id);

  const [selectedTypeFilter, setSelectedTypeFilter] = useState("");

  const columns = [
    {
      name: "Name",
      selector: (row) => (
        <a
          href={"/candidate/" + row.candidate_id}
          style={{ textTransform: "capitalize" }}
        >
          {row.candidate_first_name + " " + row.candidate_last_name}
        </a>
      ),
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.candidate_email,
    },
    {
      name: "Phone",
      selector: (row) => row.candidate_phone,
    },
    {
      name: "Nursing License",
      selector: (row) => (row.candidate_licence == "Y" ? "Yes" : "No"),
      sortable: true,
    },
    {
      name: "Nursing Degree",
      selector: (row) => row.candidate_degree,
      sortable: true,
    },
    {
      name: "Document Status",
      selector: (row) => documentStatus[row.document_status],
      sortable: true,
    },
    {
      name: "",
      selector: (row) => (
        <a
          title="View Documents"
          style={{ color: "#000" }}
          href={"/candidate/documents/" + row.candidate_id}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="acorn-icons acorn-icons-file-text mb-3 d-inline-block text-primary"
          >
            <path d="M6.5 18H13.5C14.9045 18 15.6067 18 16.1111 17.6629C16.3295 17.517 16.517 17.3295 16.6629 17.1111C17 16.6067 17 15.9045 17 14.5V7.44975C17 6.83775 17 6.53175 16.9139 6.24786C16.8759 6.12249 16.8256 6.00117 16.7638 5.88563C16.624 5.62399 16.4076 5.40762 15.9749 4.97487L14.0251 3.02513L14.0251 3.02512C13.5924 2.59238 13.376 2.37601 13.1144 2.23616C12.9988 2.1744 12.8775 2.12415 12.7521 2.08612C12.4682 2 12.1622 2 11.5503 2H6.5C5.09554 2 4.39331 2 3.88886 2.33706C3.67048 2.48298 3.48298 2.67048 3.33706 2.88886C3 3.39331 3 4.09554 3 5.5V14.5C3 15.9045 3 16.6067 3.33706 17.1111C3.48298 17.3295 3.67048 17.517 3.88886 17.6629C4.39331 18 5.09554 18 6.5 18Z"></path>
            <path d="M13 6 7 6M13 10 7 10M13 14 7 14"></path>
          </svg>
        </a>
      ),
    },
  ];

  const pagination = (page, totalRows) => {
    setLoading(true);
    setPending(true);
    _loadCandidates(page);
    setLoading(false);
  };

  const search = () => {
    var q = document.getElementById("q").value;

    setPending(true);
    setLoading(true);

    post("admin/candidates/search", { q: q, type: "search" })
      .then(async function (response) {
        setCandidates(response.data.data);
        setTotalRows(response.data.totalRows);
        setPending(false);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const filter = (filter) => {
    //var filter = document.getElementById('filter').value;
    setSelectedTypeFilter(filter);
    setLoading(true);
    post("admin/candidates/search", { q: filter, language: selectedLanguageFilter, type: "filter" })
      .then(async function (response) {
        setLoading(false);
        setCandidates(response.data.data);
        setTotalRows(response.data.totalRows);
        setPending(false);

        var table = window.$("#datatableRows").DataTable();
        table.destroy();

        setTimeout(function () {
          var uninitialized = window.$("#datatableRows").filter(function () {
            return !window.$.fn.DataTable.fnIsDataTable(this);
          });

          uninitialized.each(function () {
            window.$(this).dataTable({
              dom: "Bfrtip",
              buttons: [
                {
                  extend: "excel",
                  text: "Export Excel",
                  className: "btn btn-light",
                  exportOptions: {
                    columns: "th:not(:last-child)",
                  },
                },
                {
                  extend: "csv",
                  text: "Export Csv",
                  className: "btn btn-light",
                  exportOptions: {
                    columns: "th:not(:last-child)",
                  },
                },
              ],
            });
          });
        }, 100);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const filterByDate = () => {
    var date_from = document.getElementById("start_date").value;
    var date_to = document.getElementById("end_date").value;
    setLoading(true);

    post("admin/candidates/search", {
      date_from: date_from,
      date_to: date_to,
      type: "date",
    })
      .then(async function (response) {
        setLoading(false);
        setCandidates(response.data.data);
        setTotalRows(response.data.totalRows);
        setPending(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const changeStatus = (candidateId, status) => {
    setLoading(true);
    post("admin/candidates/status_change", {
      candidate_id: candidateId,
      status: status,
    })
      .then(async function (response) {
        _loadCandidates();
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        console.log(error);
      });
  };

  const _filterByLang = (e) => {
    setSelectedLanguageFilter(e.target.value);
    setLoading(true);
    post("admin/candidates/search", {
      q: selectedTypeFilter,
      language: e.target.value,
      type: "filter",
    })
      .then(async function (response) {
        setLoading(false);
        setCandidates(response.data.data);
        setTotalRows(response.data.totalRows);
        setPending(false);

        var table = window.$("#datatableRows").DataTable({
          fixedColumns: {
            left: 1,
            right: 1,
          },
        });
        table.destroy();

        setTimeout(function () {
          var uninitialized = window.$("#datatableRows").filter(function () {
            return !window.$.fn.DataTable.fnIsDataTable(this);
          });

          uninitialized.each(function () {
            window.$(this).dataTable({
              dom: "Bfrtip",
              fixedColumns: {
                left: 1,
                right: 1,
              },
              buttons: [
                {
                  extend: "excel",
                  text: "Export Excel",
                  className: "btn btn-light",
                  exportOptions: {
                    columns: "th:not(:last-child)",
                  },
                },
                {
                  extend: "csv",
                  text: "Export Csv",
                  className: "btn btn-light",
                  exportOptions: {
                    columns: "th:not(:last-child)",
                  },
                },
              ],
            });
          });
        }, 100);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  const _recommended = (candidateId) => {
    setLoading(true);
    post("admin/candidates/recommended", {
      candidate_id: candidateId,
    })
      .then(async function (response) {
        _loadCandidates(1);
        setLoading(false);

        success({
          title: "SUCCESS",
          text: "Successfully added to recommended list",
          modules: new Map([
            [
              Confirm,
              {
                confirm: true,
                buttons: [
                  {
                    text: "Ok",
                    primary: true,
                    click: (notice) => {
                      notice.close();
                    },
                  },
                ],
              },
            ],
          ]),
        });
      })
      .catch(function (error) {
        setLoading(false);
        console.log(error);
      });
  };

  var documentStatus = {
    0: "Pending",
    1: "Submitted",
    2: "Waiting Proposal",
    3: "Waiting Payment",
    4: "Translation Progress",
    5: "Translation Under Review",
    6: "Completed",
  };

  const columnsD = [
    {
      name: "Title",
      selector: (row) => row.title,
    },
    {
      name: "Year",
      selector: (row) => row.year,
    },
  ];

  const data = [
    {
      id: 1,
      title: "Beetlejuice",
      year: "1988",
    },
    {
      id: 2,
      title: "Ghostbusters",
      year: "1984",
    },
  ];

  function replaceString(str) {
    if (str == undefined) {
      return "";
    }

    return str.replace("_", " ");
  }

  const _del = (id) => {
    if (window.confirm("Are you sure you want to delete?") == true) {
      setLoading(true);
      post("admin/candidates/delete", {
        candidate_id: id,
      })
        .then(async function (response) {
          _loadCandidates(1);
          setLoading(false);

          success({
            title: "SUCCESS",
            text: "Successfully deleted",
            modules: new Map([
              [
                Confirm,
                {
                  confirm: true,
                  buttons: [
                    {
                      text: "Ok",
                      primary: true,
                      click: (notice) => {
                        notice.close();
                      },
                    },
                  ],
                },
              ],
            ]),
          });
        })
        .catch(function (error) {
          setLoading(false);
          console.log(error);
        });
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setToastMessage(text + " copied to clipboard!");
        setToastType("success");
      })
      .catch((err) => {
        console.error("Could not copy text: ", err);
        setToastMessage("Failed to copy " + text + " to clipboard");
        setToastType("error");
      });
  };

  const closeToast = () => {
    setToastMessage(null);
  };

  return (
    <>
      <Header />
      <Loader loading={loading} />

      <main>
        <div class="container-fluid">
          <div class="row">
            {/* Menu Start */}
            <SideBar />
            {/*  Menu End */}

            {/* Page Content Start */}
            <div class="col">
              {/* Title and Top Buttons Start */}
              <div class="page-title-container">
                <div class="row g-0">
                  <div class="col-auto mb-2 mb-md-0 me-auto">
                    <div class="w-auto sw-md-30">
                      <h1 class="mb-0 pb-0 card-title" id="title">
                        Candidates
                      </h1>
                    </div>
                  </div>
                  <div class="w-100 d-md-none"></div>
                  <div class="col-12 col-sm-6 col-md d-flex align-items-start justify-content-end order-3 order-sm-2">
                    <div class="w-100 w-lg-auto search-input-container border border-separator">
                      <input
                        class="form-control search"
                        type="text"
                        autocomplete="off"
                        placeholder="Search"
                      />
                      <span class="search-magnifier-icon">
                        <i data-acorn-icon="search"></i>
                      </span>
                    </div>
                  </div>
                  <div class="col-12 col-sm-6 col-md-auto d-flex align-items-start justify-content-end mb-2 mb-sm-0 order-sm-3">
                    <div
                      class="dropdown-as-select d-inline-block"
                      data-childSelector="span"
                    >
                      <button
                        class="btn p-0"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <span
                          class="btn btn-outline-primary dropdown-toggle"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-delay="0"
                          title="Candidate filter"
                        ></span>
                      </button>
                      <div class="dropdown-menu dropdown-menu-end">
                        <a
                          class="dropdown-item active"
                          href="javascript:;"
                          onClick={() => filter("")}
                        >
                          Filter By
                        </a>
                        <a
                          class="dropdown-item"
                          href="javascript:;"
                          onClick={() => filter("licensed")}
                        >
                          Registered Nurses
                        </a>
                        <a
                          class="dropdown-item"
                          href="javascript:;"
                          onClick={() => filter("non-licensed")}
                        >
                          Nurses
                        </a>
                        <a
                          class="dropdown-item"
                          href="javascript:;"
                          onClick={() => filter("students")}
                        >
                          Students
                        </a>
                        <a
                          class="dropdown-item"
                          href="javascript:;"
                          onClick={() => filter("other")}
                        >
                          Others
                        </a>
                        <a
                          class="dropdown-item"
                          href="javascript:;"
                          onClick={() => filter("KDA Brochure")}
                        >
                          KDA Brochure Readers
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Title and Top Buttons End */}

              {/*FILTER
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <div class="input-group">
                                                <input id="q" placeholder="Enter Name/Email/Phone" type="text" class="form-control" aria-label="Text input with segmented dropdown button" />
                                                <button type="button" class="btn btn-outline-primary" onClick={() => search()}>
                                                    <i data-acorn-icon="search" class="icon" data-acorn-size="18"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div className='col-sm-2'>
                                            <div class="dropdown-as-select d-inline-block" data-childSelector="span">
                                                <button class="btn p-0" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <span
                                                        class="btn btn-outline-primary dropdown-toggle"
                                                        data-bs-toggle="tooltip"
                                                        data-bs-placement="top"
                                                        data-bs-delay="0"
                                                        title="Item Count"
                                                    ></span>
                                                </button>
                                                <div class="dropdown-menu dropdown-menu-end">
                                                    <a class="dropdown-item active" href="javascript:;" onClick={() => filter('')}>Filter By</a>
                                                    <a class="dropdown-item" href="javascript:;" onClick={() => filter('licensed')}>Licensed</a>
                                                    <a class="dropdown-item" href="javascript:;" onClick={() => filter('non-licensed')}>Non-Licensed</a>
                                                    <a class="dropdown-item" href="javascript:;" onClick={() => filter('students')}>Students</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div class="input-daterange input-group" id="datePickerRange">
                                                <input type="text" class="form-control" name="start" id="start_date" placeholder="Start Date" />
                                                <span class="mx-2"></span>
                                                <input type="text" class="form-control" name="end" id="end_date" placeholder="End Date" />
                                                <span class="mx-2"></span>
                                                <button type="button" class="btn btn-outline-primary mb-1" onClick={() => filterByDate()}>Search</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-12'>
                                            <DataTable
                                                columns={columns}
                                                data={candidates}
                                                defaultSortFieldId={1}
                                                fixedHeader
                                                responsive={true}
                                                striped={true}
                                                highlightOnHover={true}
                                                progressPending={pending}
                                                pagination
                                                paginationServer={true}
                                                paginationPerPage={paginationPerPage}
                                                paginationTotalRows={totalRows}
                                                onChangePage={pagination}
                                            />
    </div>
                                    </div>
                                </div>
    </div>*/}
              <div class="card mb-5 p0" style={{ padding: 0 }}>
                <div class="card-body p0" style={{ padding: 10 }}>
                  {languageLevelsId.map((l, k) => (
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        id={l.id}
                        value={l.id}
                        name="filter"
                        checked={selectedLanguageFilter === l.id}
                        onChange={(e) => 
                          _filterByLang(e)
                        }
                      />
                      <label class="form-check-label" for={l.id}>
                        {l.val}
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              {/*<DataTable
                  columns={columns}
                  data={candidates}
                  defaultSortFieldId={1}
                  fixedHeader
                  responsive={true}
                  striped={true}
                  highlightOnHover={true}
                  progressPending={pending}
                  pagination
                  paginationServer={true}
                  paginationPerPage={paginationPerPage}
                  paginationTotalRows={totalRows}
                  onChangePage={pagination}
                  />*/}

              <div className="card mb-3">
                <div className="card-body">
                  <h5 className="card-title">Count by candidate type</h5>
                  <div className="d-flex justify-content-between">
                    {Object.entries(candidateTypeCounts).map(
                      ([type, count]) => {
                        if (
                          type === null ||
                          type === undefined ||
                          type === "null"
                        ) {
                          return null;
                        }
                        if (count !== null && count !== undefined) {
                          return (
                            <div key={type}>
                              <strong>{replaceString(type)}:</strong> {count}
                            </div>
                          );
                        }
                        return null;
                      }
                    )}
                  </div>
                </div>
              </div>
              <div class="data-table-rows slim">
                <div
                  class="data-table-responsive-wrapper"
                  style={{ overflowX: "auto", width: "100%" }}
                >
                  <table
                    id="datatableRows"
                    class="data-table nowrap hover"
                    style={{ minWidth: "100%" }}
                  >
                    <thead>
                      <tr>
                        <th class="text-muted text-small text-uppercase">#</th>
                        <th class="text-muted text-small text-uppercase">Type</th>
                        <th class="text-muted text-small text-uppercase">
                          Name
                        </th>
                        <th class="text-muted text-small text-uppercase">
                          Phone
                        </th>
                        <th class="text-muted text-small text-uppercase">
                          Email
                        </th>
                        {/*<th class="text-muted text-small text-uppercase">Nursing License</th>*/}
                        {/* <th class="text-muted text-small text-uppercase">Type</th> */}
                        <th class="text-muted text-small text-uppercase">
                          Documents
                        </th>
                        <th class="text-muted text-small text-uppercase">
                          Affliator
                        </th>
                        <th class="text-muted text-small text-uppercase">
                          Created
                        </th>
                        {/*<th class="text-muted text-small text-uppercase">Status</th>*/}
                        <th class="text-muted text-small text-uppercase">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {candidates.map((c, k) => {
                        // Calculate the background color based on documents_count
                        let backgroundColor = "";
                        if (c.documents_count <= 4)
                          backgroundColor = "#FFD1D9"; // Low documents
                        else if (c.documents_count <= 8)
                          backgroundColor = "#FBD3CB"; // Medium documents
                        else backgroundColor = "#ADF0DD"; // High documents

                        return (
                          <tr key={k} style={{ backgroundColor }}>
                            <th scope="row">{k + 1}</th>

                            <td>{c.candidate_degree}</td>
                            <td title={c.candidate_degree}>
                              <a
                                href={"/candidate/" + c.candidate_id}
                                style={{ color: "black" }}
                              >
                                <span
                                  class="text-capitalize"
                                  id="contactName"
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: 5,
                                  }}
                                >

                                  {c.candidate_first_name +
                                    " " +
                                    c.candidate_last_name}
                                </span>
                              </a>
                              <div>
                                {c.candidate_interview_status === "selected"
                                  ? " [Got Placement] "
                                  : ""}
                              </div>
                            </td>
                            <td>
                              <div
                                className="contact-hover-wrapper"
                                onMouseEnter={() =>
                                  setHoveredPhone(c.candidate_id)
                                }
                                onMouseLeave={() => setHoveredPhone(null)}
                                style={{
                                  position: "relative",
                                  display: "inline-block",
                                }}
                              >
                                <div
                                  style={{
                                    width: 100,
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                  title={c.candidate_phone}
                                >
                                  {c.candidate_phone}
                                </div>
                                <div
                                  className="copy-option"
                                  style={{
                                    display:
                                      hoveredPhone === c.candidate_id
                                        ? "flex"
                                        : "none",
                                    position: "absolute",
                                    right: "-20px",
                                    top: "50%",
                                    transform: "translateY(-50%)",
                                    background: "rgba(0, 0, 0, 0.5)",
                                    color: "white",
                                    padding: "2px 5px",
                                    borderRadius: "4px",
                                    cursor: "pointer",
                                    zIndex: 1002,
                                  }}
                                >
                                  <Clipboard
                                    size={20}
                                    onClick={() =>
                                      copyToClipboard(c.candidate_phone)
                                    }
                                  />
                                </div>
                              </div>
                            </td>

                            <td>
                              <div
                                className="contact-hover-wrapper"
                                onMouseEnter={() =>
                                  setHoveredEmail(c.candidate_id)
                                }
                                onMouseLeave={() => setHoveredEmail(null)}
                                style={{
                                  position: "relative",
                                  display: "inline-block",
                                }}
                              >
                                <div
                                  style={{
                                    width: 100,
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                  title={c.candidate_email}
                                >
                                  {c.candidate_email}
                                </div>
                                <div
                                  className="copy-option"
                                  style={{
                                    display:
                                      hoveredEmail === c.candidate_id
                                        ? "flex"
                                        : "none",
                                    position: "absolute",
                                    right: "-20px",
                                    top: "50%",
                                    transform: "translateY(-50%)",
                                    background: "rgba(0, 0, 0, 0.5)",
                                    color: "white",
                                    padding: "2px 5px",
                                    borderRadius: "4px",
                                    cursor: "pointer",
                                    zIndex: 1002,
                                  }}
                                >
                                  <Clipboard
                                    size={20}
                                    onClick={() =>
                                      copyToClipboard(c.candidate_email)
                                    }
                                  />
                                </div>
                              </div>
                            </td>
                            <td>{c.documents_count}</td>
                            <td>{c.utm_source}</td>
                            <td>{c.created}</td>
                            <td>
                              <a
                                title="View Documents"
                                style={{ color: "#0000" }}
                                href={"/candidate/documents/" + c.candidate_id}
                              >
                                <span
                                  class="badge bg-outline-dark group"
                                  id="contactGroup"
                                  style={{
                                    whiteSpace: "nowrap",
                                    maxWidth: "100px",
                                    display: "inline-block",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  View Documents
                                </span>
                              </a>

                              <span class="">
                                <a
                                  class="mt-3 ms-4"
                                  href="#"
                                  role="button"
                                  id="dropdownMenuLink"
                                  data-bs-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                  style={{ color: "black" }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    class="acorn-icons acorn-icons-more-vertical d-inline-block"
                                  >
                                    <path d="M10 9C10.5523 9 11 9.44772 11 10V10C11 10.5523 10.5523 11 10 11V11C9.44772 11 9 10.5523 9 10V10C9 9.44772 9.44772 9 10 9V9zM10 2C10.5523 2 11 2.44772 11 3V3C11 3.55228 10.5523 4 10 4V4C9.44772 4 9 3.55228 9 3V3C9 2.44772 9.44772 2 10 2V2zM10 16C10.5523 16 11 16.4477 11 17V17C11 17.5523 10.5523 18 10 18V18C9.44772 18 9 17.5523 9 17V17C9 16.4477 9.44772 16 10 16V16z"></path>
                                  </svg>
                                </a>
                                <div
                                  class="dropdown-menu"
                                  aria-labelledby="dropdownMenuLink"
                                >
                                  <a
                                    className="dropdown-item"
                                    href="#"
                                    onClick={() => _loginAs(c.candidate_id)}
                                  >
                                    {"Login as " + c.candidate_first_name}
                                  </a>

                                  <a
                                    class="dropdown-item"
                                    href={
                                      process.env.REACT_APP_BASE_URL +
                                      "candidates/download/en" +
                                      c.candidate_id
                                    }
                                    target="_blank"
                                  >
                                    Download Resume
                                  </a>

                                  <a
                                    class="dropdown-item"
                                    href={
                                      process.env.REACT_APP_BASE_URL +
                                      "candidates/download/emp" +
                                      c.candidate_id
                                    }
                                    target="_blank"
                                  >
                                    Download Employer Resume
                                  </a>

                                  {c.candidate_status === 0 && (
                                    <a
                                      class="dropdown-item"
                                      href="#"
                                      onClick={() =>
                                        changeStatus(c.candidate_id, 1)
                                      }
                                    >
                                      Change Status to Attend Meeting
                                    </a>
                                  )}

                                  {c.candidate_status === 1 && (
                                    <a
                                      class="dropdown-item"
                                      href="#"
                                      onClick={() =>
                                        changeStatus(c.candidate_id, 0)
                                      }
                                    >
                                      Change Status to Inactive
                                    </a>
                                  )}

                                  {c.candidate_recommended === 0 && (
                                    <a
                                      class="dropdown-item"
                                      href="#"
                                      onClick={() =>
                                        _recommended(c.candidate_id)
                                      }
                                    >
                                      Add to recommended
                                    </a>
                                  )}

                                  <a
                                    class="dropdown-item"
                                    href="javascript:void(0);"
                                    onClick={() => _del(c.candidate_id)}
                                  >
                                    Delete
                                  </a>
                                </div>
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              {toastMessage && (
                <Toast
                  message={toastMessage}
                  type={toastType}
                  onClose={closeToast}
                />
              )}
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
};

export default withParams(Candidates);
